import { BaseDevice } from '@iot-platform/models/common';
// TODO should be moved under @iot-platform/models/common
import { CommandType, DeviceStatusName } from '@iot-platform/models/i4b';
import { get } from 'lodash';

export class DeviceHelpers {
  public static isCommandEnabled = (device: BaseDevice, command: CommandType): boolean => {
    const commands = get(device, ['outgoingConnector', 'requestConfiguration', 'commands'], []);
    const authenticationType = get(device, ['outgoingConnector', 'requestConfiguration', 'authentication']);
    const login = get(device, ['credential', 'loginAdmin']);
    const password = get(device, ['credential', 'passAdmin']);
    const status = get(device, ['status', 'name']);
    return !!(
      commands.includes(command) &&
      status !== DeviceStatusName.decommissioned.toString() &&
      (authenticationType !== 'login' || (authenticationType === 'login' && !!login && !!password))
    );
  };
  public static isRefreshCommandEnabled = (device: BaseDevice): boolean => DeviceHelpers.isCommandEnabled(device, CommandType.REFRESH);
  public static isGetConfigCommandEnabled = (device: BaseDevice): boolean => DeviceHelpers.isCommandEnabled(device, CommandType.SELFCONF);
  public static isUnblockCommandEnabled = (device: BaseDevice): boolean => DeviceHelpers.isCommandEnabled(device, CommandType.UNBLOCK);
  public static isGetAlarmCommandEnabled = (device: BaseDevice): boolean => DeviceHelpers.isCommandEnabled(device, CommandType.GET_ALARM);
  public static isConfigureCommandEnabled = (device: BaseDevice): boolean => !!get(device, ['incomingConnector', 'configuration', 'url']);
}
