import { computed, Injectable, Signal } from '@angular/core';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { CommandType } from '@iot-platform/models/i4b';
import { DeviceDetails } from '@iot-platform/models/xmqtt';
import { FavoriteViewsActions } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { DevicesActions } from '../actions';
import { DevicesSelectors } from '../selectors/devices.selectors';

@Injectable({
  providedIn: 'root'
})
export class DevicesFacade extends BaseFacade<DeviceDetails, Pagination, Filter> {
  canUpdateDevice: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    // For xmqtt V2 we check only on CREATE rule
    // So the canUpdateDevice should be concidered as canCreateDevice
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.CREATE);
  });
  canCreateDevice: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.CREATE);
  });
  canReadHistory: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.AUDIT_TRAIL, AuthorizationType.READ);
  });
  _deactivateActions: Signal<boolean> = this.store.selectSignal(this.selector.selectDeactivateActions);
  deactivateActions: Signal<boolean> = computed(() => {
    // eslint-disable-next-line no-underscore-dangle
    const deactivateActions = this._deactivateActions();
    const canUpdateDevice = this.canUpdateDevice();
    return !(canUpdateDevice && !deactivateActions);
  });
  canSendRefreshCommand: Signal<boolean> = this.store.selectSignal(this.selector.selectCanSendRefreshCommand);
  gridConfiguration: Signal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }> = this.store.selectSignal(fromGrids.selectXmqttDevicesGridsConfiguration);
  grids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.store.selectSignal(fromGrids.selectXmqttDevicesGrids);
  currentGrid = computed(() => this.gridConfiguration()?.currentGrid);

  constructor(
    protected override store: Store,
    protected override selector: DevicesSelectors,
    protected readonly authFacade: AuthFacade,
    protected readonly authorizationService: AuthorizationService
  ) {
    super(store, selector);
  }

  getAll(request?: CommonApiRequest) {
    this.store.dispatch(DevicesActions.loadDevices({ request }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(DevicesActions.setFilters({ filters }));
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'xmqtt-devices', filters }));
  }

  loadDeviceById(id: string): void {
    this.store.dispatch(DevicesActions.loadDeviceById({ id }));
  }

  updateDevice(device: DeviceDetails): void {
    this.store.dispatch(DevicesActions.updateDevice({ device }));
  }

  setCurrentDevice(device: DeviceDetails): void {
    this.store.dispatch(DevicesActions.setCurrentDevice({ device }));
  }

  sendCommand(device: DeviceDetails, command: CommandType): void {
    this.store.dispatch(DevicesActions.sendCommand({ device, command }));
  }

  bulkEditDevices(devices: DeviceDetails[], properties: { [key: string]: unknown }): void {
    this.store.dispatch(DevicesActions.bulkEditDevices({ devices, properties }));
  }

  updateDeviceInCurrentGrid(item: DeviceDetails): void {
    const gridId = this.gridConfiguration()?.currentGrid?.id;
    if (gridId) {
      this.store.dispatch(
        GridsDbActions.updateItemInGridData({
          gridId,
          item,
          concept: 'xmqtt-devices'
        })
      );
    }
  }
}
