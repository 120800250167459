import { Inject, Injectable } from '@angular/core';
import { AbstractTrackingSettingsProviderService, CURRENT_URL, PREVIOUS_URL, TrackingData } from '@iot-platform/core';
import { TrackingFacade } from '@iot-platform/xmqtt/data-access';
import { Observable } from 'rxjs';

@Injectable()
export class TrackingSettingsProviderService extends AbstractTrackingSettingsProviderService {
  constructor(
    @Inject(CURRENT_URL) private readonly currentUrl$: Observable<string>,
    @Inject(PREVIOUS_URL) private readonly previousUrl$: Observable<string>,
    private readonly trackingFacade: TrackingFacade
  ) {
    super();
  }

  getTrackingData$(): Observable<TrackingData> {
    return this.trackingFacade.additionalTrackingData$;
  }

  getCurrentUrl$(): Observable<string> {
    return this.currentUrl$;
  }

  getPreviousUrl$(): Observable<string> {
    return this.previousUrl$;
  }
}
