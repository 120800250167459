import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { ENVIRONMENT } from '@iot-platform/core';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Affiliate, BusinessProfile, CommonGenericModel, Environment } from '@iot-platform/models/common';
import { Timezone } from '@iot-platform/models/xmqtt';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly authFacade: AuthFacade = inject(AuthFacade);

  getAffiliates(): Observable<Affiliate[]> {
    const businessProfile: BusinessProfile = this.authFacade.selectedBusinessProfile();
    return this.http.get<Affiliate[]>(`${this.environment.api.url}${this.environment.api.endpoints.affiliates}?entityId=${businessProfile.entityId}`).pipe(
      map((data: Affiliate[]) =>
        data.sort(SortUtil.sortBy('id', true)).map((affiliate: Affiliate) => ({
          ...affiliate,
          label: `${affiliate.id} : ${affiliate.name}`
        }))
      ),
      catchError(() => of([]))
    );
  }

  getOperators(): Observable<CommonGenericModel[]> {
    return this.http.get<string[]>(`${this.environment.api.url}${this.environment.api.endpoints.operators}`).pipe(
      map((data: string[]) =>
        data.sort().map((operator: string) => ({
          id: operator,
          name: operator
        }))
      ),
      catchError(() => of([]))
    );
  }

  getTimezones(): Observable<Timezone[]> {
    return this.http.get<Timezone[]>(`${this.environment.api.url}${this.environment.api.endpoints.timezones}`).pipe(
      map((result: Timezone[]) =>
        result.map((item: Timezone) => ({
          ...item,
          key: `${item.windows} ${item.iana}`,
          value: item.windows
        }))
      ),
      catchError(() => of([]))
    );
  }

  getDefaultImage(): string {
    return 'assets/images/device/Device_Default.png';
  }
}
