import { Signal } from '@angular/core';
import { CommonGenericModel } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Observable } from 'rxjs';

export abstract class AbstractDeviceFilesService<T extends CommonGenericModel> {
  abstract deactivateActions: Signal<boolean>;
  abstract canUpdateDevice: Signal<boolean>;
  abstract canCreateDevice: Signal<boolean>;
  abstract hasFiles: Signal<boolean>;

  abstract getStaticGridDefinition(): Observable<I4BGrid<I4BGridOptions, I4BGridData> | null>;

  abstract onEditFiles(device: T): void;
}
