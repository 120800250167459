import { Injectable } from '@angular/core';
import { AbstractDeviceFilesFacade } from '@iot-platform/feature/device-files';
import { DeviceFile } from '@iot-platform/models/common';
import { DeviceDetails } from '@iot-platform/models/xmqtt';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { DeviceFilesActions } from '../actions/device-files.actions';
import { DeviceFilesSelectors } from '../selectors/device-files.selectors';

@Injectable({
  providedIn: 'root'
})
export class DeviceFilesFacade extends AbstractDeviceFilesFacade<DeviceDetails> {
  constructor(
    protected override store: Store,
    protected override selector: DeviceFilesSelectors
  ) {
    super(store, selector);
  }

  getAll() {
    noop();
  }

  setFilters() {
    noop();
  }

  loadDeviceFiles(device: DeviceDetails) {
    this.store.dispatch(DeviceFilesActions.loadDeviceFiles({ device }));
  }

  bulkEditFiles(files: DeviceFile[]): void {
    this.store.dispatch(DeviceFilesActions.bulkEditFiles({ files }));
  }
}
