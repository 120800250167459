import { Injectable } from '@angular/core';
import { ApiFeatureSelector, DeviceFile, Filter, Pagination } from '@iot-platform/models/common';
import * as fromDeviceFiles from '../reducers/device-files.reducer';

@Injectable({
  providedIn: 'root'
})
export class DeviceFilesSelectors extends ApiFeatureSelector<fromDeviceFiles.State, DeviceFile, Pagination, Filter> {
  constructor() {
    super(fromDeviceFiles.deviceFilesFeature.selectXmqttDeviceFilesState, fromDeviceFiles.adapter);
  }
}
