import { BaseState, DeviceFile, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DeviceFilesActions } from '../actions/device-files.actions';

export type State = BaseState<DeviceFile, Pagination, Filter>;

export const adapter: EntityAdapter<DeviceFile> = createEntityAdapter<DeviceFile>({
  selectId: (entity: DeviceFile) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const deviceFilesFeature = createFeature({
  name: 'xmqttDeviceFiles',
  reducer: createReducer(
    initialState,

    on(
      DeviceFilesActions.loadDeviceFiles,
      (state: State): State => ({
        ...state,
        entity: null
      })
    ),

    on(DeviceFilesActions.bulkEditFilesSuccess, (state: State, { response: files }): State => {
      const changes = Object.values(state.entities).map((file: DeviceFile) => ({
        id: file.id,
        changes: files.find((f) => f.id === file.id)
      }));
      return adapter.updateMany(changes, { ...state });
    }),

    on(
      DeviceFilesActions.loadDeviceFilesSuccess,
      (state: State, { response }): State =>
        adapter.setAll(response, {
          ...state
        })
    ),

    on(
      DeviceFilesActions.loadDeviceFiles,
      (state: State): State => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      DeviceFilesActions.loadDeviceFilesSuccess,
      (state: State): State => ({
        ...state,
        loading: false,
        loaded: true
      })
    ),
    on(
      DeviceFilesActions.loadDeviceFilesFailure,
      (state: State, { error }): State => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    )
  )
});
