import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, DeviceFile, Environment, Pagination } from '@iot-platform/models/common';
import { CommandType, Device } from '@iot-platform/models/i4b';
import { BulkOperationApiResponse, DeviceDetails, XmqttDevice } from '@iot-platform/models/xmqtt';
import { DeviceFileHelpers } from '@iot-platform/util/devices';
import { get } from 'lodash';
import { map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly http: HttpClient = inject(HttpClient);

  getAll(request: CommonApiRequest): Observable<CommonApiResponse<DeviceDetails, Pagination>> {
    const params: HttpParams = ApiHelpers.getHttpParams(request);
    return this.http
      .get<CommonApiListResponse<Device>>(`${this.environment.api.url}${this.environment.api.endpoints.xmqttDevices}`, { params })
      .pipe(map((response: CommonApiListResponse<DeviceDetails>) => ApiHelpers.getHttpResponse<DeviceDetails>(response)));
  }

  // Load base device details (from Iot4bos)
  getI4bDeviceById(id: string): Observable<Device> {
    // Expanded variables are needed later to determine the energy mode
    return this.http
      .get<CommonApiListResponse<Device>>(`${this.environment.api.url}${this.environment.api.endpoints.devices}?device_id=${id}`)
      .pipe(map((response: CommonApiListResponse<Device>) => get(response, 'content[0]')));
  }

  // Load extended device information from a connector (from xmqtt)
  getDeviceDetailsByIdentifier(id: string): Observable<XmqttDevice> {
    return this.http.get<XmqttDevice>(`${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${id}`);
  }

  // Combine both of base device and xmqtt device details (from Iot4bos and xmqtt)
  getDeviceDetails(id: string): Observable<DeviceDetails> {
    return this.getI4bDeviceById(id).pipe(
      switchMap((baseDevice: Device) =>
        this.getDeviceDetailsByIdentifier(baseDevice.identifier).pipe(
          map((xmqttDevice: XmqttDevice) => ({
            ...baseDevice,
            ...xmqttDevice
          }))
        )
      )
    );
  }

  updateDevice(device: DeviceDetails): Observable<DeviceDetails> {
    return this.http
      .patch<DeviceDetails>(`${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${device.identifier}`, device)
      .pipe(switchMap(() => this.getDeviceDetails(device.id)));
  }

  sendCommand(device: DeviceDetails, command: CommandType): Observable<DeviceDetails> {
    return this.http.post<DeviceDetails>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${device.identifier}/commands/${command.toUpperCase()}`,
      {}
    );
  }

  loadFiles(devices: DeviceDetails[]): Observable<DeviceFile[]> {
    const identifiers = devices.map((d) => d.identifier);
    return this.http
      .post<DeviceFile[]>(`${this.environment.api.url}${this.environment.api.endpoints.xFlowOperations}/bulk-list-files`, {
        identifiers
      })
      .pipe(map((response: DeviceFile[]) => DeviceFileHelpers.getFiles({ response }, ['response'])));
  }

  bulkEditFiles(deviceFiles: DeviceFile[]): Observable<DeviceFile[]> {
    return this.http
      .post<DeviceFile[]>(`${this.environment.api.url}${this.environment.api.endpoints.xFlowOperations}/bulk-update-files`, deviceFiles)
      .pipe(map((response: DeviceFile[]) => DeviceFileHelpers.getFiles({ response }, ['response'])));
  }

  bulkEditDevices(devices: DeviceDetails[], properties: Partial<DeviceDetails>): Observable<BulkOperationApiResponse> {
    const identifiers = devices.map((d) => d.identifier);
    return this.http.post<BulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.xFlowOperations}/bulk-update`, {
      identifiers,
      ...properties
    });
  }
}
