import { inject, Pipe, PipeTransform } from '@angular/core';
import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';
import { AssetVariable, DeviceVariable, FollowedVariable } from '@iot-platform/models/i4b';
import { NumberFormatPipe } from '../number-format-pipe/number-format.pipe';

@Pipe({
  name: 'valueUnitFormat',
  standalone: true
})
export class ValueUnitFormatPipe implements PipeTransform {
  private numberFormatPipe: NumberFormatPipe = inject(NumberFormatPipe);

  transform(variable?: DeviceVariable | AssetVariable | FollowedVariable): string {
    if (!variable) {
      return '';
    } else if (ProcessMultipleStateVariablesUtil.isUnitMultipleState(variable.unit)) {
      return ProcessMultipleStateVariablesUtil.getProcessedUnit(variable) ?? '';
    } else {
      return `${this.numberFormatPipe.transform(variable.lastValue?.value) ?? '-'} ${variable.unit ?? ''}`.trim();
    }
  }
}
