import { Route } from '@angular/router';
import { AuthGuard, LoginCallbackComponent, LoginShellComponent } from '@iot-platform/auth';
import { NavigationRoutes } from '@iot-platform/models/xmqtt';
import { provideXmqttApplicationState } from '@iot-platform/xmqtt/data-access';

export const APP_ROUTES: Route[] = [
  {
    path: NavigationRoutes.LOGIN,
    children: [
      {
        path: '',
        component: LoginShellComponent
      },
      {
        path: NavigationRoutes.LOGIN_CALLBACK,
        component: LoginCallbackComponent
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('@iot-platform/xmqtt/feature/app-shell').then((m) => m.AppShellRoutes),
    canActivate: [AuthGuard],
    providers: [provideXmqttApplicationState()]
  },
  { path: NavigationRoutes.HOME, redirectTo: `/${NavigationRoutes.DEVICES}` },
  { path: '', redirectTo: `/${NavigationRoutes.HOME}`, pathMatch: 'full' },
  { path: '**', redirectTo: `/${NavigationRoutes.HOME}` }
];
