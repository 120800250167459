import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { DeviceFilesEffects } from '../effects/device-files.effects';
import { DevicesEffects } from '../effects/devices.effects';
import { deviceFilesFeature } from './device-files.reducer';
import { devicesFeature } from './devices.reducer';

export function provideDevicesState(): EnvironmentProviders[] {
  return [provideState(devicesFeature), provideEffects(DevicesEffects)];
}

export function provideDeviceFilesState(): EnvironmentProviders[] {
  return [provideState(deviceFilesFeature), provideEffects(DeviceFilesEffects)];
}
