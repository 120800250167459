import { SortUtil } from '@iot-platform/iot-platform-utils';
import { CommonIndexedPagination } from '@iot-platform/models/common';
import {
  AssetEventsGridData,
  AssetsGridData,
  DaliaDeviceTemplatesGridData,
  DaliaSensorsGridData,
  DeviceEventsGridData,
  DevicesGridData,
  FirmwaresGridData,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  KercomDevicesGridData,
  SitesGridData,
  XmqttDevicesGridData
} from '@iot-platform/models/grid-engine';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromGridsDb from './grids-db.reducer';

export const gridsFeatureKey = 'grids';

export interface GridsState {
  [fromGridsDb.gridsDbFeatureKey]: fromGridsDb.State;
}

export interface State {
  [gridsFeatureKey]: GridsState;
}

export function reducers(state: GridsState | undefined, action: Action) {
  return combineReducers({
    [fromGridsDb.gridsDbFeatureKey]: fromGridsDb.reducer
  })(state, action);
}

export const selectGridsState = createFeatureSelector<GridsState>(gridsFeatureKey);
export const selectGridsDbState = createSelector(selectGridsState, (state: GridsState) => state[fromGridsDb.gridsDbFeatureKey]);

export const {
  selectIds: getGridsIds,
  selectEntities: getGridsEntities,
  selectAll: getAllGrids,
  selectTotal: getTotalGrids
} = fromGridsDb.adapter.getSelectors(selectGridsDbState);

export const getSitesGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'SITES'.toLowerCase()));
export const getAssetsGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'ASSETS'.toLowerCase()));
export const getDevicesGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'DEVICES'.toLowerCase()));
export const getDeviceEventsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DEVICE-EVENTS'.toLowerCase())
);

export const getAssetEventsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'ASSET-EVENTS'.toLowerCase())
);

export const selectAssetTemplateGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'ASSET-TEMPLATES'.toLowerCase())
);

export const selectUsersGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'USERS'.toLowerCase()));

export const selectConnectorsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'CONNECTORS'.toLowerCase())
);

export const getSelectedGridId = createSelector(selectGridsDbState, fromGridsDb.getSelectedGridId);
export const getSelectedGrid = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getSelectedGridByConcept = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getSelectedGridData = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId].data);
export const getSelectedItemIdInSelectedGrid = createSelector(selectGridsDbState, fromGridsDb.getSelectedGridId);
export const getSelectedIds = createSelector(selectGridsDbState, fromGridsDb.getSelectedIds);
export const getDefaultGrids = createSelector(selectGridsDbState, fromGridsDb.getDefaultGrids);
export const selectRefreshActivated = createSelector(selectGridsDbState, fromGridsDb.selectRefreshActivated);

export const selectSelectedGridByMasterView = (masterview: string) =>
  createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, allGrids) => {
    if (allGrids && defaultGrids && defaultGrids[masterview]) {
      const defaultGrid = defaultGrids[masterview];
      return allGrids[defaultGrid.gridId];
    } else {
      return undefined;
    }
  });
//
export const getDefaultSitesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['sites']) {
    const defaultGrid = defaultGrids['sites'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, SitesGridData> | undefined;
  }
});

export const getDefaultAssetsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['assets']) {
    const defaultGrid = defaultGrids['assets'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetsGridData> | undefined;
  }
});

export const getDefaultDevicesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['devices']) {
    const defaultGrid = defaultGrids['devices'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DevicesGridData> | undefined;
  }
});

export const getDefaultDeviceEventsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['device-events']) {
    const defaultGrid = defaultGrids['device-events'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;
  }
});

export const getDefaultAssetEventsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['asset-events']) {
    const defaultGrid = defaultGrids['asset-events'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultEmailTemplatesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['email-templates']) {
    const defaultGrid = defaultGrids['email-templates'];
    const grid = grids[defaultGrid.gridId];
    return grid;
  }
});

export const getDefaultAssetTemplatesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['asset-templates']) {
    const defaultGrid = defaultGrids['asset-templates'];
    const grid = grids[defaultGrid.gridId];
    return grid;
  }
});

export const getDefaultUsersGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['users']) {
    const defaultGrid = defaultGrids['users'];
    const grid = grids[defaultGrid.gridId];
    return grid;
  }
});

export const selectDefaultConnectorsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['connectors']) {
    const defaultGrid = defaultGrids['connectors'];
    const grid = grids[defaultGrid.gridId];
    return grid;
  }
});
/// ////////////////////////
export const selectDefaultGridByMasterview = (masterview: string) =>
  createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
    if (defaultGrids && defaultGrids[masterview] && grids) {
      return grids[defaultGrids[masterview].gridId];
    }
  });

export const selectTotalByGrid = (masterview: string) =>
  createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
    if (defaultGrids && defaultGrids[masterview] && grids) {
      if (grids[defaultGrids[masterview].gridId].data) {
        return (grids[defaultGrids[masterview].gridId]?.data.response.pagination as CommonIndexedPagination).total;
      } else {
        return 0;
      }
    }
  });

export const selectDataLoadedByGrid = (masterview: string) =>
  createSelector(getDefaultGrids, getSelectedIds, (defaultGrids, selectedIds) => {
    if (defaultGrids && defaultGrids[masterview] && selectedIds) {
      return selectedIds[defaultGrids[masterview].gridId].dataLoaded;
    }
  });

export const selectSortByGrid = (masterview: string) =>
  createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
    if (defaultGrids && defaultGrids[masterview] && grids) {
      return grids[defaultGrids[masterview].gridId].gridOptions.gridSort;
    }
  });
/// ///////////////////////////

export const selectDefaultStockSiteDevicesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['stock-site-devices']) {
    const defaultGrid = defaultGrids['stock-site-devices'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DevicesGridData> | undefined;
  }
});

export const selectDefaultActiveAssetEventsPopupGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['active-asset-events-popup']) {
    const defaultGrid = defaultGrids['active-asset-events-popup'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultAssetEventsByPERuleGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['asset-events-by-pe-rule']) {
    const defaultGrid = defaultGrids['asset-events-by-pe-rule'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultDeviceEventsPERuleGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['device-events-by-pe-rule']) {
    const defaultGrid = defaultGrids['device-events-by-pe-rule'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;
  }
});

export const getDefaultAssetEventsByTopicGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['asset-events-by-topic']) {
    const defaultGrid = defaultGrids['asset-events-by-topic'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultDeviceEventsByTopicGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['device-events-by-topic']) {
    const defaultGrid = defaultGrids['device-events-by-topic'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;
  }
});

export const getDefaultAssetEventsBySiteGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['asset-events-by-site']) {
    const defaultGrid = defaultGrids['asset-events-by-site'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultDeviceEventsBySiteGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['device-events-by-site']) {
    const defaultGrid = defaultGrids['device-events-by-site'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;
  }
});

export const getDefaultAssetEventsByAssetGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['asset-events-by-asset']) {
    const defaultGrid = defaultGrids['asset-events-by-asset'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultDeviceEventsByDeviceGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['device-events-by-device']) {
    const defaultGrid = defaultGrids['device-events-by-device'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;
  }
});

export const getDataLoadingByGrid = (gridId: string) => createSelector(getSelectedIds, (ids) => ids[gridId].dataLoading);

export const getSortByGrid = (gridId: string) => createSelector(getGridsEntities, (ids) => (ids[gridId] ? ids[gridId].gridOptions.gridSort : []));

export const getDataLoadedByGrid = (gridId: string) =>
  createSelector(getSelectedIds, (ids) => {
    if (gridId && ids && ids[gridId]) {
      return ids[gridId].dataLoaded;
    } else {
      return true;
    }
  });

export const getSelectedItemIdInGrid = (gridId: string) => createSelector(getSelectedIds, (ids) => ids[gridId].selectedItemId);

export const getSelectedItemInSelectedGrid = createSelector(getGridsEntities, getSelectedGridId, getSelectedIds, (entities, selectedGridId, selectedIds) => {
  if (selectedGridId && selectedIds && entities) {
    const selectedItemId = selectedIds[selectedGridId].selectedItemId;
    return entities[selectedGridId].data?.response?.data?.find((data) => data.id === selectedItemId);
  }
});

export const selectItemInGrid = (gridId: string, itemId: string) =>
  createSelector(getGridsEntities, (entities) => {
    if (entities) {
      return entities[gridId].data.response.data.filter((data) => data.id === itemId)[0];
    }
  });

export const selectSiteGridsConfiguration = createSelector(getDefaultSitesGrid, getSitesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectAssetGridsConfiguration = createSelector(getDefaultAssetsGrid, getAssetsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectDeviceGridsConfiguration = createSelector(getDefaultDevicesGrid, getDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectAssetEventGridsConfiguration = createSelector(getDefaultAssetEventsGrid, getAssetEventsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectDeviceEventGridsConfiguration = createSelector(getDefaultDeviceEventsGrid, getDeviceEventsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectAssetTemplateGridsConfiguration = createSelector(getDefaultAssetTemplatesGrid, selectAssetTemplateGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectUsersGridsConfiguration = createSelector(getDefaultUsersGrid, selectUsersGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectConnectorsGridsConfiguration = createSelector(selectDefaultConnectorsGrid, selectConnectorsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * DALIA devices selectors
 */
export const selectDaliaDevicesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DALIA-DEVICES'.toLowerCase())
);
export const selectDefaultDaliaDevicesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['dalia-devices']) {
    const defaultGrid = defaultGrids['dalia-devices'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DevicesGridData> | undefined;
  }
  return null;
});
export const selectDaliaDevicesGridsConfiguration = createSelector(selectDefaultDaliaDevicesGrid, selectDaliaDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * DALIA firmwares selectors
 */
export const selectDaliaFirmwaresGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DALIA-FIRMWARES'.toLowerCase())
);
export const selectDefaultDaliaFirmwaresGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['dalia-firmwares']) {
    const defaultGrid = defaultGrids['dalia-firmwares'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, FirmwaresGridData> | undefined;
  }
  return null;
});
export const selectDaliaFirmwaresGridsConfiguration = createSelector(selectDefaultDaliaFirmwaresGrid, selectDaliaFirmwaresGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * DALIA sensors selectors
 */
export const selectDaliaSensorsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DALIA-SENSORS'.toLowerCase())
);
export const selectDefaultDaliaSensorsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['dalia-sensors']) {
    const defaultGrid = defaultGrids['dalia-sensors'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DaliaSensorsGridData> | undefined;
  }
  return null;
});
export const selectDaliaSensorsGridsConfiguration = createSelector(selectDefaultDaliaSensorsGrid, selectDaliaSensorsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * DALIA device templates selectors
 */
export const selectDaliaDeviceTemplatesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DALIA-DEVICE-TEMPLATES'.toLowerCase())
);
export const selectDefaultDaliaDeviceTemplatesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['dalia-device-templates']) {
    const defaultGrid = defaultGrids['dalia-device-templates'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DaliaDeviceTemplatesGridData> | undefined;
  }
  return null;
});
export const selectDaliaDeviceTemplatesGridsConfiguration = createSelector(
  selectDefaultDaliaDeviceTemplatesGrid,
  selectDaliaDeviceTemplatesGrids,
  (defaultGrid, grids) => ({
    sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, DaliaDeviceTemplatesGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
    currentGrid: defaultGrid,
    isGridsLoading: false
  })
);

/**
 * KERCOM devices selectors
 */
export const selectKercomDevicesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'KERCOM-DEVICES'.toLowerCase())
);
export const selectDefaultKercomDevicesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['kercom-devices']) {
    const defaultGrid = defaultGrids['kercom-devices'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, KercomDevicesGridData> | undefined;
  }
  return null;
});
export const selectKercomDevicesGridsConfiguration = createSelector(selectDefaultKercomDevicesGrid, selectKercomDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * XMQTT devices selectors
 */
export const selectXmqttDevicesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'XMQTT-DEVICES'.toLowerCase())
);
export const selectDefaultXmqttDevicesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['xmqtt-devices']) {
    const defaultGrid = defaultGrids['xmqtt-devices'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, XmqttDevicesGridData> | undefined;
  }
  return null;
});
export const selectXmqttDevicesGridsConfiguration = createSelector(selectDefaultXmqttDevicesGrid, selectXmqttDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));
