import { DeviceFile } from '@iot-platform/models/common';
import { DeviceDetails } from '@iot-platform/models/xmqtt';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceFilesActions = createActionGroup({
  source: 'Xmqtt Device Files',
  events: {
    'Load Device Files': props<{
      device: DeviceDetails;
    }>(),
    'Load Device Files Success': props<{
      response: DeviceFile[];
    }>(),
    'Load Device Files Failure': props<{
      error: unknown;
    }>(),

    'Bulk edit files': props<{
      files: DeviceFile[];
    }>(),
    'Bulk edit files Success': props<{
      response: DeviceFile[];
    }>(),
    'Bulk edit files Failure': props<{
      error: unknown;
    }>()
  }
});
