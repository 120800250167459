import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AbstractAuditTrailService } from '@iot-platform/audit-trail';
import { ENVIRONMENT } from '@iot-platform/core';
import { ApiHelpers, DiffUtils } from '@iot-platform/iot-platform-utils';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, Environment, Pagination } from '@iot-platform/models/common';
import { get } from 'lodash';
import { noop, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type HistoryLogParams = CommonApiRequest;

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService extends AbstractAuditTrailService<Partial<HistoryLogParams>> {
  protected readonly httpClient: HttpClient = inject(HttpClient);
  protected readonly environment: Environment = inject(ENVIRONMENT);

  getBaseUrl(id: string) {
    // TODO should be centralized in backed and expose a common audit trail endpoint
    return `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${id}/trails`;
  }

  getAll(params: Partial<HistoryLogParams>): Observable<CommonApiResponse<HistoryLog, Pagination>> {
    return this.httpClient
      .get<CommonApiListResponse<HistoryLog>>(this.getBaseUrl(params.additionalOptions.elementId))
      .pipe(map((response: CommonApiListResponse<HistoryLog>) => ApiHelpers.getHttpResponse<HistoryLog>(response)))
      .pipe(
        map((response: CommonApiResponse<HistoryLog, Pagination>) => ({
          ...response,
          data: response.data.map((item) => ({ ...item, concept: params.additionalOptions.concept }))
        }))
      );
  }

  getById(params: { id: string } & Partial<HistoryLogParams>): Observable<HistoryLogDetail> {
    return this.httpClient.get<HistoryLogDetail>(`${this.getBaseUrl(params.additionalOptions.elementId)}/${params.id}`).pipe(
      map((logDetail: HistoryLogDetail) => {
        if (logDetail.updatedAttributes.includes('currentConfiguration')) {
          const oldConfig = get(logDetail, 'oldImage.currentConfiguration', {});
          const newConfig = get(logDetail, 'newImage.currentConfiguration', {});
          const predicate = (a: { v: number | string }, b: { v: number | string }): boolean => a?.v !== b?.v;
          logDetail.oldImage.currentConfiguration = { ...DiffUtils.diffObjectsWith(oldConfig, newConfig, predicate) };
          logDetail.newImage.currentConfiguration = { ...DiffUtils.diffObjectsWith(newConfig, oldConfig, predicate) };
        }
        return logDetail;
      })
    );
  }

  exportLogDetails(): void {
    noop();
  }
}
