import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { provideFeatureMasterViewSettingsFactory } from '@iot-platform/feature/master-view';
import { FeatureDeviceFilesSettings } from '../models/device-files-settings.model';
import { AbstractDeviceFilesFacade } from '../services/abstract-device-files-facade.service';
import { AbstractDeviceFilesService } from '../services/abstract-device-files.service';
import { DeviceFilesMasterViewService } from '../services/device-files-master-view.service';
import { getDeviceFilesMasterViewSettings } from './device-files-master-view.provider';

export const FEATURE_DEVICE_FILES_SETTINGS = new InjectionToken<FeatureDeviceFilesSettings>('FEATURE_DEVICE_FILES_SETTINGS');

export function provideFeatureDeviceFilesSettings(
  settings: FeatureDeviceFilesSettings & {
    deviceFilesService: Type<AbstractDeviceFilesService<any>>;
    deviceFilesFacade: Type<AbstractDeviceFilesFacade<any>>;
  }
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: AbstractDeviceFilesService,
      useClass: settings.deviceFilesService
    },
    {
      provide: AbstractDeviceFilesFacade,
      useClass: settings.deviceFilesFacade
    },
    {
      provide: FEATURE_DEVICE_FILES_SETTINGS,
      useValue: {
        ...settings
      }
    },
    provideFeatureMasterViewSettingsFactory({
      authFacade: AuthFacade,
      masterViewFacade: settings.deviceFilesFacade,
      masterViewService: DeviceFilesMasterViewService,
      settingsFactory: () => getDeviceFilesMasterViewSettings(settings)
    })
  ]);
}
