import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceDetails } from '@iot-platform/models/xmqtt';
import { DeviceHelpers } from '@iot-platform/xmqtt/util/devices';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as fromDevices from '../reducers/devices.reducer';

@Injectable({
  providedIn: 'root'
})
export class DevicesSelectors extends ApiFeatureSelector<fromDevices.State, DeviceDetails, Pagination, Filter> {
  selectTotal: MemoizedSelector<fromDevices.State, number> = createSelector(this.selectState, (state: fromDevices.State): number => state.pagination.total);

  selectDeactivateActions: MemoizedSelector<fromDevices.State, boolean> = createSelector(this.selectState, (state: fromDevices.State) => state.loading);

  selectCanSendRefreshCommand: MemoizedSelector<fromDevices.State, boolean> = createSelector(this.selectCurrentEntity, (device: DeviceDetails): boolean =>
    DeviceHelpers.canSendRefreshCommand(device)
  );

  constructor() {
    super(fromDevices.devicesFeature.selectDevicesState, fromDevices.adapter);
  }
}
