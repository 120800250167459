<div class="iot-platform-feature-device-files-tab" fxFlex fxLayout="column" fxLayoutGap="10px">

  <iot-platform-ui-section-header
    (dispatchEvent)="onDispatchSectionHeaderEvent($event)"
    [actions]="sectionHeaderActions()"
    [title]="'DEVICES.FILES_TAB.SECTION_HEADER.TITLE' | translate: { count: totalFiles() }"
  />

  <iot-platform-feature-master-view [masterView]="masterViewName" />
</div>
