import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { AuditTrailModule } from '@iot-platform/audit-trail';
import { AuditTrailService } from './audit-trail.service';

export { GridCtaService } from './grid-cta.service';
export { FavoriteFilterEngineService } from './favorite-filter-engine.service';
export { GoogleAnalyticsMapperService } from './google-analytics-mapper.service';
export { TrackingSettingsProviderService } from './tracking-settings-provider.service';


export const provideXmqttAuditTrailSettings = (): EnvironmentProviders =>
  importProvidersFrom(
    AuditTrailModule.forRoot({
      auditTrailService: AuditTrailService,
      exportLogDetails: false
    })
  );
