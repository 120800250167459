<mat-card class='dialog-card device-files-form-dialog'>
  <mat-card-title class='dialog-card-header'>
    <mat-toolbar class='dialog-toolbar' color='accent' fxLayout='row' fxLayoutAlign='space-between center'>
      <h1 class='dialog-card-title'>{{ 'DEVICES.FILES_POPUP.TITLE' | translate }}</h1>
      <button (click)='close()' class='regular-round-button' mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class='dialog-card-content device-files-form-dialog__content' fxLayout='row' fxLayout.lt-md="column" fxLayoutGap='20px'>

    <section fxFlex fxLayout='column'>
      <mat-toolbar color='primary' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='10px'>
        <section
          fxFlex="70%"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <p>{{ 'DEVICES.FILES_POPUP.AVAILABLE_FILES' | translate | uppercase }} ({{ leftRowNodeCount() }})</p>
        </section>
        <section
          fxFlex="30%"
          fxLayoutAlign="end center"
          fxLayoutGap="10px"
        >
          <button
            (click)='onClearFilters(leftGridApi)'
            [disabled]="!enableLeftClearAppliedFilter"
            class='button-regular device-files-form-dialog__clear-applied-filter-btn device-files-form-dialog__clear-applied-filter-btn--left'
            color="accent"
            mat-button
            matTooltipClass="regular-tooltip"
          >
            {{ 'DEVICES.CALL_LOG.RESET_FILTERS' | translate }}
          </button>
        </section>
      </mat-toolbar>

      <div
        (dragover)="gridDragOver($event)"
        (dragstart)="onDragStart($event, leftGridApi)"
        (drop)="gridDrop($event, 'left')"
        class='device-files-form-dialog__content_left-grid'
        fxFlex
      >
        <ag-grid-angular
          (firstDataRendered)="onFirstDataRendered($event)"
          (gridReady)="onLeftGridReady($event)"
          [columnDefs]="columnDefs"
          [gridOptions]="leftGridOptions"
          [localeText]="getLocaleTexts()"
          [overlayNoRowsTemplate]="loading() ? '<span>'+('DEVICES.FILES_POPUP.LOADING' | translate)+'</span>' : '<span>'+('DEVICES.FILES_POPUP.NO_FILES_TO_SHOW' | translate)+'</span>'"
          [rowData]="availableFiles()"
          class="ag-theme-material"
          style="width: 100%; height: 350px"
        >
        </ag-grid-angular>

      </div>
    </section>

    <section fxFlex fxLayout='column'>
      <mat-toolbar color='primary' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='10px'>
        <section
          fxFlex="70%"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <p>{{ 'DEVICES.FILES_POPUP.SELECTED_FILES' | translate | uppercase }} ({{ rightRowNodeCount() }})</p>
        </section>
        <section
          fxFlex="30%"
          fxLayoutAlign="end center"
          fxLayoutGap="10px"
        >
          <button
            (click)='onClearFilters(rightGridApi)'
            [disabled]="!enableRightClearAppliedFilter"
            class='button-regular device-files-form-dialog__clear-applied-filter-btn device-files-form-dialog__clear-applied-filter-btn--right'
            color="accent"
            mat-button
            matTooltipClass="regular-tooltip"
          >
            {{ 'DEVICES.CALL_LOG.RESET_FILTERS' | translate }}
          </button>
        </section>
      </mat-toolbar>

      <div
        (dragover)="gridDragOver($event)"
        (dragstart)="onDragStart($event, rightGridApi)"
        (drop)="gridDrop($event, 'right')"
        class='device-files-form-dialog__content_right-grid'
        fxFlex
      >
        <ag-grid-angular
          (firstDataRendered)="onFirstDataRendered($event)"
          (gridReady)="onRightGridReady($event)"
          [columnDefs]="columnDefs"
          [gridOptions]="rightGridOptions"
          [localeText]="getLocaleTexts()"
          [overlayNoRowsTemplate]="loading() ? '<span>'+('DEVICES.FILES_POPUP.LOADING' | translate)+'</span>' : '<span>'+('DEVICES.FILES_POPUP.NO_FILES_TO_SHOW' | translate)+'</span>'"
          [rowData]="selectedFiles()"
          class="ag-theme-material"
          style="width: 100%; height: 350px"
        >
        </ag-grid-angular>

      </div>
    </section>

  </mat-card-content>

  <mat-card-actions class='dialog-card-actions' fxLayout='row' fxLayoutAlign='end'>
    <button (click)='close()' class='button-regular' mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)='save()' [disabled]='!(leftRowNodeCount() + rightRowNodeCount())' class='button-regular'
            color='accent' mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
