import { GoogleAnalyticsTrackingTag, TrackingData, TrackingProviders, TrackingSettings } from '@iot-platform/core';
import { TrackingEventCategories, TrackingEventIds, TrackingEventLabels, TrackingEventNames } from '@iot-platform/models/xmqtt';
import { GoogleAnalyticsMapperService } from '../services/google-analytics-mapper.service';

export const trackingSettings: TrackingSettings = {
  tags: {
    /**
     Devices master view
     */
    [TrackingEventIds.NAVIGATE_TO_DEVICES_MASTER_VIEW]: /* 👈 track from route path */ {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.NAVIGATION,
        label: TrackingEventLabels.NAVIGATE_TO_DEVICES_MASTER_VIEW
      })
    },
    [TrackingEventIds.OPEN_DEVICES_FILTER_ENGINE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: data.filterEngineOpened ? TrackingEventLabels.OPEN_FILTER_ENGINE : TrackingEventLabels.CLOSE_FILTER_ENGINE
      })
    },
    [TrackingEventIds.REFRESH_DEVICES_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_DATA
      })
    },
    [TrackingEventIds.EXPORTS_DEVICES_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.EXPORT_DATA
      })
    },
    [TrackingEventIds.APPLY_DEVICES_FILTERS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.FILTER_ACTION,
        label: TrackingEventLabels.APPLY_FILTERS
      })
    },
    [TrackingEventIds.NAVIGATE_TO_DEVICE_OVERVIEW_PAGE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.NAVIGATION,
        label: TrackingEventLabels.NAVIGATE_TO_DEVICE_OVERVIEW_PAGE
      })
    },
    [TrackingEventIds.SEND_DEVICE_SHELL_COMMAND]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.SEND_COMMAND
      })
    },
    [TrackingEventIds.OPEN_BULK_DEVICE_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_BULK_DEVICE_EDIT_FORM
      })
    },
    [TrackingEventIds.BULK_UPDATE_DEVICE_DETAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.BULK_UPDATE_DEVICE_DETAILS
      })
    },
    [TrackingEventIds.OPEN_BULK_DEVICES_EDIT_FILES_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_BULK_DEVICES_EDIT_FILES_FORM
      })
    },
    [TrackingEventIds.BULK_UPDATE_DEVICES_FILES]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.BULK_UPDATE_DEVICES_FILES
      })
    },
    /**
     Devices overview view
     */
    [TrackingEventIds.REFRESH_DEVICE_OVERVIEW_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_DATA
      })
    },
    [TrackingEventIds.NAVIGATE_TO_DEVICES_MASTER_VIEW_FROM_DEVICE_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.NAVIGATE_TO_DEVICES_MASTER_VIEW
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_DETAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_INFO
      })
    },
    [TrackingEventIds.DEVICE_DETAILS_TAB_CHANGE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_CHANGE,
        label: data.tabLabel as string
      })
    },
    [TrackingEventIds.SEND_DEVICE_OVERVIEW_COMMAND]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.SEND_COMMAND
      })
    },
    [TrackingEventIds.OPEN_DEVICE_AUDIT_TRAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_HISTORY
      })
    },
    [TrackingEventIds.OPEN_DEVICE_NAME_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_NAME_EDIT_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_AFFILIATE_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_AFFILIATE_EDIT_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_GENERAL_INFO_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_GENERAL_INFO_EDIT_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_EDIT_FILES_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_EDIT_FILES_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_FILES]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_FILES
      })
    }
  },
  providers: [{ provider: TrackingProviders.GoogleAnalytics, mapper: GoogleAnalyticsMapperService }]
};
