import { EnvironmentProviders } from '@angular/core';
import { provideAuditTrailSettings } from '@iot-platform/audit-trail';
import { DevicesAuditTrailService } from './devices-audit-trail.service';

export { DevicesService } from './devices.service';

export const provideXmqttAuditTrailSettings = (): EnvironmentProviders =>
  provideAuditTrailSettings({
    auditTrailService: DevicesAuditTrailService
  });
