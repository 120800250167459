import { CellClassParams, CellClassRules, CellStyle, RowNode } from '@ag-grid-community/core';
import { I4BColumn } from '../definitions';
import { I4bCellConcept } from '../enums';
import { I4BColumnHeader } from '../models';
import { I4BColumnOptions } from '../options';
import { I4BGridFilterParams } from './i4b-filter-configuration';

export enum I4BCellType {
  ACTIVE_EVENTS_CELL = 'activeEventsCell',
  ADDRESS_ACCURACY = 'addressAccuracy',
  ARRAY_OF_OBJECTS = 'arrayOfObjects',
  ASSET_TEMPLATE = 'assetTemplate',
  ASSET_VARIABLE_CELL = 'assetVariableCell',
  ASSET_VARIABLE_THRESHOLD_CELL = 'assetVariableThresholdCell',
  BASIC = 'basicCell',
  BASIC_LINK = 'basicLink',
  BOOLEAN_CELL = 'booleanCell',
  BUTTON_CELL = 'buttonCell',
  CALL_TO_ACTION = 'callToActionCell',
  COLOR_CELL = 'colorCell',
  COMMENT = 'comment',
  COUNT = 'count',
  CRON_READABLE_CELL = 'cronReadableCell',
  CYCLICAL_CALL_FAULT = 'cyclicalCallFaultCell',
  DATE = 'dateCell',
  DIAGNOSTIC_VARIABLE = 'diagnosticVariable',
  DYNAMIC_DATA = 'dynamicData',
  DYNAMIC_ICON_CELL = 'dynamicIconCell',
  EMAIL_TEMPLATE = 'emailTemplate',
  ENTITY_CELL = 'EntityCell',
  EVENT_DURATION_CELL = 'eventDurationCell',
  FOLLOWED_NUMBER_CELL = 'followedNumberCell',
  GRID_NAME_CELL = 'gridNameCell',
  ICON = 'icon',
  ICON_LINK = 'iconLink',
  LAST_COMMAND_STATUS_CELL = 'lastCommandStatusCell',
  LINKED_CELL = 'linkedCell',
  NUMBER = 'numberCell',
  OUTGOING_CONNECTOR = 'outgoingConnector',
  PRODUCT_CELL = 'productCell',
  RATING = 'rating',
  RICH_VARIABLE = 'richVariableValueCell',
  RICH_VARIABLE_UNIT_CELL = 'richVariableUnitCell',
  SEVERITY_CELL = 'severityCell',
  TAG_CELL = 'tagCell',
  THRESHOLD_ICON_CELL = 'thresholdIconCell',
  TOPIC_SELF_SUBSCRIPTION_CELL = 'topicSelfSubscriptionCell',
  TRANSLATED_CELL = 'translatedCell',
  USER_CELL = 'userCell',
  VALUE_TO_ICON = 'valueToIcon',
  VARIABLE_DISPATCH_CELL = 'variableDispatchCell',
  VARIABLE_VALUE_CELL = 'variableValueCell'
}

export interface I4BCellOptions {
  type?: string;
  translateKey?: string;
  tooltip?: { key: string } | string;
  capitalize?: boolean;
  datatype?: string; // TODO : REMOVE THIS
  translatedValues?: {
    [key: string]: Partial<{
      value: string;
      color: string;
      backgroundColor: string;
      align: string;
    }>;
  };
  textAlign?: string;
  dataToDisplayIfFalse?: string;
  dataToDisplayIfTrue?: string;
  mode?: string;
  toFixed?: number;
}

export interface I4BStatusCellOptions extends I4BCellOptions {
  withStatusPadding: boolean;
  class: any;
}

export interface I4BDynamicIconCellOptions extends I4BCellOptions {
  icon: string;
  class: string;
  tooltip: { key: string };
}

export interface I4RichVariableCellOptions extends I4BCellOptions {
  dataType: string;
  unit: string;
  value: string;
  tooltip: string;
}

export interface VariableCellOptions extends Partial<I4RichVariableCellOptions> {
  translatedValues: {
    [key: string]: Partial<{
      value: string;
      color: string;
      backgroundColor: string;
      align: string;
    }>;
  };
  combineWithUnit: boolean;
  textAlign: string;
}

export interface I4ThresholdCellOptions extends I4BCellOptions {
  position?: number;
}

export interface I4BArrayOfObjectCellOptions extends I4BCellOptions {
  attributes?: string[];
  expressions?: { key: string; value: any }[];
  separator?: string;
}

export interface I4BDiagnosticVariableCellOptions extends I4BCellOptions {
  displayMode: DiagnosticVariableCellOptionsDisplayModeType;
  variableCategory: string;
}

export type DiagnosticVariableCellOptionsDisplayModeType = 'value' | 'datetime';

export interface I4BCellEvent {
  type: string;
  options?: any;
  dispatchEvent?: any;
}

export interface I4BCell {
  type: I4BCellType;
  // eslint-disable-next-line @typescript-eslint/ban-types
  valueGetter?: string | Function;
  cellStyle?: (params: CellClassParams) => CellStyle;
  options?: I4BCellOptions;
  event?: I4BCellEvent;
  cellClassRules?: CellClassRules;
}

export interface I4BStatusCell extends I4BCell {
  options?: I4BStatusCellOptions;
}

export interface I4BDynamicIconCell extends I4BCell {
  type: I4BCellType.DYNAMIC_ICON_CELL;
  options?: I4BDynamicIconCellOptions;
}

export interface I4BButtonCell extends I4BCell {
  visibleConditions: any; // [{ key: string; value: boolean }][];
  disableConditions?: any;
  enableConditions: any; // [{ key: string; value: boolean }][];
  singleActions: {
    order: number;
    label: string;
    key: string;
    enableConditions: any; // [{ key: string; value: boolean }][];
    visibleConditions: any; // [{ key: string; value: boolean }][];
    disableConditions?: any;
    divider?: boolean;
  }[];
  bulkActions?: {
    order: number;
    label: string;
    key: string;
    enableConditions: any; // [{ key: string; value: boolean }][];
    visibleConditions: any; // [{ key: string; value: boolean }][];
    disableConditions?: any;
    divider?: boolean;
  }[];
}

export interface I4BSelectionCell extends I4BCell {
  id: string;
  name: string;
}

export interface I4BRichVariableCell extends I4BCell {
  options: I4RichVariableCellOptions | Partial<VariableCellOptions>;
  valueGetter?: any;
}

export interface I4BThresholdCell extends I4BCell {
  options?: I4ThresholdCellOptions;
}

export interface I4BArrayOfObjectCell extends I4BCell {
  options?: I4BArrayOfObjectCellOptions;
}

export interface I4BDiagnosticVariableCell extends I4BCell {
  options?: Partial<I4BDiagnosticVariableCellOptions>;
}

export interface I4BColumnConfiguration {
  cell: I4BCell;
  id: string | null;
  width: number;
  position: string | null;
  sortProperty: string | null;
  concept: I4bCellConcept;
  resizable?: boolean;
  isDefault: boolean;
  isConfigurable: boolean;
  translationKey?: string;
  isLink: boolean;
  children?: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[];
  marryChildren?: boolean;
  clickEvent?: {
    type: string;
    options?: string | { followedNumber: number } | { targetType: string; targetId: string; siteId: string };
  };
  comparator?: (valueA: any, ValueB: any, rowNodeA?: RowNode, rowNodeB?: RowNode, isInverted?: boolean) => number;
  filterParams?: Partial<I4BGridFilterParams>;
  name?: string | null;
}

export interface I4BStatusColumnConfiguration extends I4BColumnConfiguration {
  cell: I4BStatusCell;
}

export interface I4BDynamicIconColumnConfiguration extends I4BColumnConfiguration {
  cell: I4BDynamicIconCell;
}

export interface I4BRichVariableColumnConfiguration extends I4BColumnConfiguration {
  cell: I4BRichVariableCell;
}

export interface I4BButtonColumnConfiguration extends I4BColumnConfiguration {
  cell: I4BButtonCell;
}

export interface I4BSelectionColumnConfiguration extends I4BColumnConfiguration {
  cell: I4BSelectionCell;
}

export interface I4BThresholdColumnConfiguration extends I4BColumnConfiguration {
  cell: I4BThresholdCell;
  isFollowedVariable: boolean;
}

export interface I4BArrayOfObjectColumnConfiguration extends I4BColumnConfiguration {
  cell: I4BArrayOfObjectCell;
}

export interface I4BDiagnosticVariableColumnConfiguration extends I4BColumnConfiguration {
  cell: I4BDiagnosticVariableCell;
}
