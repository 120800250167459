import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, effect, inject, Injector, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioChange, MatRadioGroup } from '@angular/material/radio';

import { MatFormField, MatLabel, MatOption, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatToolbar } from '@angular/material/toolbar';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { Entity, TagCategory } from '@iot-platform/models/common';

import {
  AssetVariableColumn,
  AssetVariableGroupColumn,
  ColumnFactory,
  DaliaFirmwareVersionColumn,
  DefaultActiveAssetEventsPopupGrid,
  DefaultAssetEventsByAssetGrid,
  DefaultAssetEventsByPERuleGrid,
  DefaultAssetEventsBySiteGrid,
  DefaultAssetEventsByTopicGrid,
  DefaultAssetEventsGrid,
  DefaultAssetsGrid,
  DefaultAssetTemplatesGrid,
  DefaultConnectorsGrid,
  DefaultDaliaDevicesGrid,
  DefaultDaliaDeviceTemplatesGrid,
  DefaultDaliaFirmwaresGrid,
  DefaultDaliaSensorsGrid,
  DefaultDeviceEventsByDeviceGrid,
  DefaultDeviceEventsByPERuleGrid,
  DefaultDeviceEventsBySiteGrid,
  DefaultDeviceEventsByTopicGrid,
  DefaultDeviceEventsGrid,
  DefaultDevicesGrid,
  DefaultEmailTemplatesGrid,
  defaultGridsDefinitions,
  DefaultKercomDevicesGrid,
  DefaultSitesGrid,
  DefaultStockSiteDevicesGrid,
  DefaultUsersGrid,
  DefaultXMQTTDevicesGrid,
  ExpandedVariableColumn,
  getAllColumnsByConcept,
  I4BBasicColumn,
  I4bCellConcept,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  I4BTagColumn
} from '@iot-platform/models/grid-engine';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { DiagnosticVariableCellHelper } from '../../../helpers/diagnostic-variable-cell.helper';
import { GridsService } from '../../../services/grids.service';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatCardModule,
    MatToolbar,
    MatIcon,
    ReactiveFormsModule,
    MatFormField,
    MatSelectModule,
    MatOption,
    MatRadioGroup,
    MatLabel,
    MatRadioButton,
    CdkDropList,
    CdkDrag,
    UpperCasePipe,
    MatButton,
    MatExpansionModule,
    MatInput,
    MatIconButton
  ],
  selector: 'grid-engine-grid-manager-admin-popup',
  templateUrl: './grid-manager-admin-popup.component.html',
  styleUrls: ['./grid-manager-admin-popup.component.scss']
})
export class GridManagerAdminPopupComponent implements OnInit {
  private readonly gridService: GridsService = inject(GridsService);
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  columnsForm: UntypedFormGroup;
  initialFormState: string;
  defaultGridsDefinitions = defaultGridsDefinitions;
  delayList = [120, 300, 600, 1800];
  gridPageSizeList = [10, 20, 30, 50, 100, 500, 1000, 3000];
  selectedViewDef: WritableSignal<any> = signal(null);
  selectedLibrary: WritableSignal<{
    concept: I4bCellConcept;
    libraryName?: I4bCellConcept;
    columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[];
  }> = signal({
    concept: null,
    libraryName: null,
    columns: []
  });
  selectedAppDefaultGrid: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData>> = signal(null);
  availableColumns: WritableSignal<I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]> = signal([]);
  selectedColumns: WritableSignal<I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]> = signal([]);
  public dialogRef: MatDialogRef<GridManagerAdminPopupComponent> = inject(MatDialogRef<GridManagerAdminPopupComponent>);
  public data: { grid: I4BGrid<I4BGridOptions, I4BGridData>; selectedOrganization: Entity } = inject(MAT_DIALOG_DATA);

  get actionButtonLabel(): string {
    return this.data ? 'IOT_DICTIONARY.UPDATE' : 'IOT_DICTIONARY.CREATE';
  }

  static getVariableNamesForRequest(selectedColumns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]): string[] {
    const allVariables: string[] = [];
    selectedColumns
      .filter(
        (col) =>
          col.columnId === 'e24f0f50-5ce7-11ec-9577-acde48001122-asset-variable' ||
          col.columnId === '7z348742-6b09-11ec-87d5-acda48401122-expanded-variable' ||
          col.columnId === '19bcb62c-724b-11ec-a844-acde48001122-asset-variable-group' ||
          col.columnId === '9374044f-8451-444e-8a16-6044eec9c112-diagnostic-variable-group'
      )
      .forEach((col) => {
        switch (col.columnId) {
          case '19bcb62c-724b-11ec-a844-acde48001122-asset-variable-group':
            // eslint-disable-next-line no-case-declarations
            const richCellId = col.configuration.children.filter((c) => c?.columnId === '7a788742-6f09-11ec-87d5-acde48001122-rich-variable')[0].configuration
              .id;
            allVariables.push(richCellId.substring(richCellId.indexOf('.') + 1, richCellId.length));
            break;
          case '7z348742-6b09-11ec-87d5-acda48401122-expanded-variable':
          case 'e24f0f50-5ce7-11ec-9577-acde48001122-asset-variable':
            allVariables.push(col.configuration.id.substring(col.configuration.id.indexOf('.') + 1, col.configuration.id.indexOf('.lastValue')));
            break;
          case '9374044f-8451-444e-8a16-6044eec9c112-diagnostic-variable-group':
            allVariables.push(...DiagnosticVariableCellHelper.getVariableNamesByGridColumns([col]));
            break;
          default:
            break;
        }
      });

    return allVariables;
  }

  static getConceptsFromViewType(masterView: string): string[] {
    switch (masterView.toLowerCase()) {
      case 'asset-events':
      case 'active-asset-events-popup':
        return ['SITE', 'ASSET', 'EVENT'];
      case 'device-events':
        return ['SITE', 'DEVICE', 'EVENT'];
      case 'asset-events-by-pe-rule':
      case 'device-events-by-pe-rule':
      case 'asset-events-by-site':
      case 'device-events-by-site':
      case 'asset-events-by-asset':
      case 'device-events-by-device':
      case 'asset-events-by-topic':
      case 'device-events-by-topic':
        return ['EVENT'];
      default:
        return [masterView.slice(0, -1).toUpperCase()];
    }
  }

  ngOnInit(): void {
    this.initForm();
    effect(
      () => {
        const selectedViewDef = this.selectedViewDef();
        this.columnsForm.get('viewType').setValue(selectedViewDef);
      },
      { injector: this.injector }
    );
  }

  initForm(): void {
    this.columnsForm = new UntypedFormGroup({
      name: new UntypedFormControl(GetUtils.get(this.data?.grid, 'name', null), []),
      viewType: new UntypedFormControl(GetUtils.get(this.data?.grid, 'masterview', null), [Validators.required]),
      autoRefreshDelay: new UntypedFormControl(GetUtils.get(this.data?.grid, 'gridOptions.autoRefresh.delay', 0), []),
      autoRefreshEnabled: new UntypedFormControl(GetUtils.get(this.data?.grid, 'gridOptions.autoRefresh.enabled', false), []),
      isAppDefault: new UntypedFormControl(true, []),
      isMineDefault: new UntypedFormControl(false, []),
      selectedLibrary: new UntypedFormControl(null, []),
      pageSize: new UntypedFormControl(GetUtils.get(this.data?.grid, 'gridOptions.pageSize', 100), [])
    });
  }

  setAvailableColumns(columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]): void {
    const selectedColumns = this.selectedColumns();
    const availableColumns = [...columns].filter(
      (available) => !selectedColumns.find((selected) => selected.columnId === available.columnId && selected.configuration.id === available.configuration.id)
    );
    this.availableColumns.set(availableColumns);
  }

  allowDragColumns() {
    const selectedLibrary = this.selectedLibrary();
    return (item: CdkDrag) => {
      if (get(selectedLibrary, 'libraryName') === I4bCellConcept.TAGS) {
        return get(item, 'data.configuration.concept').includes(I4bCellConcept.TAGS);
      } else {
        return get(item, 'data.configuration.concept') === get(selectedLibrary, 'libraryName');
      }
    };
  }

  save() {
    const selectedAppDefaultGrid = this.selectedAppDefaultGrid();
    const selectedColumns = this.selectedColumns();
    const variableNames = GridManagerAdminPopupComponent.getVariableNamesForRequest(selectedColumns);
    const selectedViewDef = this.selectedViewDef();
    const toSave: Partial<I4BGrid<I4BGridOptions, I4BGridData>> = {
      name: 'default',
      masterview: selectedViewDef.viewType.toLowerCase(),
      columns: selectedColumns.map((selected, idx) => {
        const overrideConfig =
          ColumnFactory.getUserColumnClass(selected.columnId).class === I4BBasicColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === AssetVariableColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === ExpandedVariableColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === AssetVariableGroupColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === I4BTagColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === DaliaFirmwareVersionColumn;
        const col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions> = {
          columnId: selected.columnId,
          className: selected.className,
          options: { ...selected.options, order: idx },
          dataLibs: selected.dataLibs,
          configuration: overrideConfig ? selected.configuration : null,
          header: overrideConfig ? selected.header : null
        };
        return col;
      }),
      isDefault: true,
      gridOptions: {
        ...selectedAppDefaultGrid.gridOptions,
        renderer: 'grid',
        buttonColumn: { enabled: false },
        autoRefresh: {
          enabled: this.columnsForm.get('autoRefreshDelay').getRawValue() !== 0,
          delay: this.columnsForm.get('autoRefreshDelay').getRawValue()
        },
        multipleSelection: false,
        pageSize: this.columnsForm.get('pageSize').value,
        filters: [],
        variableNames: [...variableNames],
        tagIds: [
          ...selectedColumns
            .filter((col: I4BBasicColumn) => col.configuration.cell.type === I4BCellType.TAG_CELL)
            .map((col: I4BBasicColumn) => col.configuration.id)
        ]
      },
      userId: null
    };
    this.dialogRef.close(toSave);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  close() {
    this.dialogRef.close();
  }

  isSubmissionDisabled(): boolean {
    return !this.columnsForm.valid || this.initialFormState === JSON.stringify(this.columnsForm.value) || this.selectedColumns().length === 0;
  }

  onMasterViewChange(event: MatSelectChange) {
    this.gridService
      .getAppDefaultGridByConcept(event.value.viewType.toLowerCase())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((grid) => {
        this.selectedAppDefaultGrid.set({ ...grid });
        this.selectedColumns.set(grid.columns);
        this.columnsForm.get('autoRefreshEnabled').setValue(GetUtils.get(grid, 'gridOptions.autoRefresh.enabled', false));
        this.columnsForm.get('autoRefreshDelay').setValue(GetUtils.get(grid, 'gridOptions.autoRefresh.delay', 0));
      });
    this.availableColumns.set([]);
    this.selectedViewDef.set(event.value);
  }

  onLibraryChange(event: MatRadioChange) {
    this.availableColumns.set([]);
    this.selectedLibrary.set(event.value);
    const selectedViewDef = this.selectedViewDef();
    const selectedLibrary = this.selectedLibrary();

    if (event.value.libraryName !== 'TAGS') {
      this.setAvailableColumns(selectedLibrary.columns);
    } else {
      const concepts: string[] = GridManagerAdminPopupComponent.getConceptsFromViewType(selectedViewDef.viewType);
      this.gridService
        .loadTagsByConceptsAndEntity(concepts, this.data.selectedOrganization.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((tagCategories: TagCategory[]) => {
          const availableColumns = [
            ...tagCategories.map(
              (tagCategory: TagCategory) =>
                new I4BTagColumn(
                  { displayName: tagCategory.name },
                  {
                    id: tagCategory.id,
                    concept: I4bCellConcept[tagCategory.concept + '_TAGS'],
                    cell: {
                      type: I4BCellType.TAG_CELL,
                      valueGetter: this.getValueGetter(selectedViewDef.viewType, tagCategory.id)
                    }
                  }
                )
            )
          ];
          this.setAvailableColumns(availableColumns);
        });
    }
  }

  getValueGetter(masterView: string, tagCategoryId: string): string {
    return (masterView.toLowerCase().includes('event') ? 'tags.' : 'expandedTagCategories.') + tagCategoryId + '.labels.0.name';
  }

  setDefaultView() {
    const selectedViewDef = this.selectedViewDef();
    // TODO : Ca c'est super nul, remplacer par un truc qui renvoie la default grid par concept sans switch
    switch (selectedViewDef.viewType.toLowerCase()) {
      case 'sites':
        this.initValuesFromSystemGrid(new DefaultSitesGrid());
        break;
      case 'assets':
        this.initValuesFromSystemGrid(new DefaultAssetsGrid());
        break;
      case 'devices':
        this.initValuesFromSystemGrid(new DefaultDevicesGrid());
        break;
      case 'dalia-devices':
        this.initValuesFromSystemGrid(new DefaultDaliaDevicesGrid());
        break;
      case 'xmqtt-devices':
        this.initValuesFromSystemGrid(new DefaultXMQTTDevicesGrid());
        break;
      case 'kercom-devices':
        this.initValuesFromSystemGrid(new DefaultKercomDevicesGrid());
        break;
      case 'dalia-firmwares':
        this.initValuesFromSystemGrid(new DefaultDaliaFirmwaresGrid());
        break;
      case 'dalia-device-templates':
        this.initValuesFromSystemGrid(new DefaultDaliaDeviceTemplatesGrid());
        break;
      case 'dalia-sensors':
        this.initValuesFromSystemGrid(new DefaultDaliaSensorsGrid());
        break;
      case 'device-events':
        this.initValuesFromSystemGrid(new DefaultDeviceEventsGrid());
        break;
      case 'asset-events':
        this.initValuesFromSystemGrid(new DefaultAssetEventsGrid());
        break;
      case 'email-templates':
        this.initValuesFromSystemGrid(new DefaultEmailTemplatesGrid());
        break;
      case 'asset-templates':
        this.initValuesFromSystemGrid(new DefaultAssetTemplatesGrid());
        break;
      case 'connectors':
        this.initValuesFromSystemGrid(new DefaultConnectorsGrid());
        break;
      case 'stock-site-devices':
        this.initValuesFromSystemGrid(new DefaultStockSiteDevicesGrid());
        break;
      case 'active-asset-events-popup':
        this.initValuesFromSystemGrid(new DefaultActiveAssetEventsPopupGrid());
        break;
      case 'asset-events-by-pe-rule':
        this.initValuesFromSystemGrid(new DefaultAssetEventsByPERuleGrid());
        break;
      case 'device-events-by-pe-rule':
        this.initValuesFromSystemGrid(new DefaultDeviceEventsByPERuleGrid());
        break;
      case 'asset-events-by-topic':
        this.initValuesFromSystemGrid(new DefaultAssetEventsByTopicGrid());
        break;
      case 'device-events-by-topic':
        this.initValuesFromSystemGrid(new DefaultDeviceEventsByTopicGrid());
        break;
      case 'asset-events-by-site':
        this.initValuesFromSystemGrid(new DefaultAssetEventsBySiteGrid());
        break;
      case 'device-events-by-site':
        this.initValuesFromSystemGrid(new DefaultDeviceEventsBySiteGrid());
        break;
      case 'asset-events-by-asset':
        this.initValuesFromSystemGrid(new DefaultAssetEventsByAssetGrid());
        break;
      case 'device-events-by-device':
        this.initValuesFromSystemGrid(new DefaultDeviceEventsByDeviceGrid());
        break;
      case 'users':
        this.initValuesFromSystemGrid(new DefaultUsersGrid());
        break;
      default:
        break;
    }
  }

  initValuesFromSystemGrid(systemGrid: I4BGrid<I4BGridOptions, I4BGridData>) {
    const selectedLibrary = this.selectedLibrary();
    this.selectedAppDefaultGrid.set({ ...systemGrid });
    this.selectedColumns.set([...systemGrid.columns]);
    this.columnsForm.get('pageSize').setValue(systemGrid.gridOptions.pageSize);
    this.setAvailableColumns(selectedLibrary?.columns ?? []);
  }

  resetHeaderName(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    const selectedViewDef = this.selectedViewDef();
    const defaultColumn: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions> = getAllColumnsByConcept(selectedViewDef.viewType).find(
      (col) => col.columnId === column.columnId && col.configuration.id === column.configuration.id
    );
    const columnToUpdate = this.selectedColumns().find((col) => col.configuration.id === column.configuration.id);
    columnToUpdate.header.displayName = defaultColumn?.header?.displayName;
    columnToUpdate.options.overrideHeaderName = null;
    this.selectedColumns.update((selectedColumns) => {
      const index = selectedColumns.findIndex((col) => col.configuration.id === column.configuration.id);
      const arr = [...selectedColumns];
      arr.splice(index, 1, columnToUpdate);
      return [...arr];
    });
  }

  applyNewHeader(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>, value: string) {
    column.options.overrideHeaderName = value;
  }

  getHeaderValue(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    return column.options.overrideHeaderName ?? column.header.displayName;
  }

  applyNewProperty(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>, value: string) {
    column.configuration.id = value;
  }

  applyNewVariableName(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>, value: string) {
    column.options.overrideHeaderName = value.toUpperCase();
    column.configuration.id = `expandedVariables.${value}.lastValue.value`;
  }

  addBasicColumn() {
    this.selectedColumns.update((selectedColumns) => [...selectedColumns, new I4BBasicColumn({}, {}, {})]);
  }

  addVariableColumn() {
    this.selectedColumns.update((selectedColumns) => [...selectedColumns, new AssetVariableColumn({}, {}, {})]);
  }
}
