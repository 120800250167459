import { inject } from '@angular/core';
import { DeviceFile } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { DevicesFacade, DevicesService } from '@iot-platform/xmqtt/data-access/devices';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of, switchMap } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { DeviceFilesActions } from '../actions/device-files.actions';

const loadDeviceFiles$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceFilesActions.loadDeviceFiles),
      switchMap((action) =>
        devicesService.loadFiles([action.device]).pipe(
          map((response: DeviceFile[]) => DeviceFilesActions.loadDeviceFilesSuccess({ response })),
          catchError((error) => of(DeviceFilesActions.loadDeviceFilesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const bulkEditFiles$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceFilesActions.bulkEditFiles),
      concatMap((action) =>
        devicesService.bulkEditFiles(action.files).pipe(
          map(() => DeviceFilesActions.bulkEditFilesSuccess({ response: action.files })),
          catchError((error) => of(DeviceFilesActions.bulkEditFilesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const bulkEditFilesSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesFacade = inject(DevicesFacade)) =>
    actions$.pipe(
      ofType(DeviceFilesActions.bulkEditFilesSuccess),
      tap(({ response }) => {
        response.map((item) => devicesFacade.updateDeviceInCurrentGrid(item));
      })
    ),
  { functional: true, dispatch: false }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceFilesActions.loadDeviceFiles, DeviceFilesActions.bulkEditFiles),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceFilesActions.loadDeviceFilesSuccess,
        DeviceFilesActions.loadDeviceFilesFailure,
        DeviceFilesActions.bulkEditFilesSuccess,
        DeviceFilesActions.bulkEditFilesFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceFilesActions.loadDeviceFilesFailure, DeviceFilesActions.bulkEditFilesFailure),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceFilesActions.bulkEditFilesSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

export const DeviceFilesEffects = {
  loadDeviceFiles$,
  showLoader$,
  hideLoader$,
  displayError$,
  displaySuccess$,
  bulkEditFiles$,
  bulkEditFilesSuccess$
};
