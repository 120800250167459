import { Host, Injectable, Optional } from '@angular/core';
import { Entity } from '@iot-platform/models/common';
import { AssetTemplate, AssetVariable } from '@iot-platform/models/i4b';
import { AbstractGridAssetVariableConfigureReadPopupService } from '../services/abstract-grid-asset-variable-configure-read-popup.service';
import { AbstractGridAssetTemplatesService } from './abstract-grid-asset-templates.service';

@Injectable({ providedIn: 'root' })
export class GridEngineSettingsService {
  constructor(
    @Host()
    @Optional()
    private readonly abstractGridAssetTemplatesService: AbstractGridAssetTemplatesService,
    @Host()
    @Optional()
    private readonly assetVariableConfigureReadPopupService: AbstractGridAssetVariableConfigureReadPopupService
  ) {}

  updateAssetVariableThresholds(assetVariable: AssetVariable): void {
    this.assetVariableConfigureReadPopupService?.updateAssetVariableThresholds?.(assetVariable);
  }

  calculateIsEditable(template: AssetTemplate, sessionEntity: Entity): boolean {
    return this.abstractGridAssetTemplatesService?.calculateIsEditable?.(template, sessionEntity);
  }
}
