import { Component, effect, inject, input, Signal, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreTrackingFacade } from '@iot-platform/core';
import { AbstractDeviceFilesService } from '@iot-platform/feature/device-files';
import { FeatureMasterViewComponent } from '@iot-platform/feature/master-view';
import { SectionHeaderAction, SectionHeaderComponent, SectionHeaderEvent } from '@iot-platform/iot-platform-ui';
import { CommonGenericModel } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureDeviceFilesSettings } from '../../models/device-files-settings.model';
import { AbstractDeviceFilesFacade } from '../../services/abstract-device-files-facade.service';
import { FEATURE_DEVICE_FILES_SETTINGS } from '../../settings/device-files.provider';

@Component({
  selector: 'iot-platform-feature-device-files-tab',
  standalone: true,
  imports: [FlexLayoutModule, FeatureMasterViewComponent, SectionHeaderComponent, TranslateModule],
  templateUrl: './device-files-tab.component.html'
})
export class DeviceFilesTabComponent<T extends CommonGenericModel> {
  device = input<T>();
  sectionHeaderActions: WritableSignal<SectionHeaderAction[]>;
  editFilesAction: SectionHeaderAction;
  protected readonly deviceFilesFacade: AbstractDeviceFilesFacade<T> = inject(AbstractDeviceFilesFacade);
  totalFiles: Signal<number> = this.deviceFilesFacade.total;
  protected readonly deviceFilesService: AbstractDeviceFilesService<any> = inject(AbstractDeviceFilesService);
  disableActions: Signal<boolean> = this.deviceFilesService.deactivateActions;
  hasFiles: Signal<boolean> = this.deviceFilesService.hasFiles;
  protected readonly trackingFacade: CoreTrackingFacade = inject(CoreTrackingFacade);
  protected readonly deviceFilesSettings: FeatureDeviceFilesSettings = inject(FEATURE_DEVICE_FILES_SETTINGS);
  readonly masterViewName = this.deviceFilesSettings.masterViewName;

  constructor() {
    this.editFilesAction = new SectionHeaderAction({
      action: 'edit-files',
      tooltip: signal('DEVICES.FILES_TAB.SECTION_HEADER.EDIT_FILES'),
      icon: signal('edit'),
      disabled: this.disableActions,
      visible: this.hasFiles
    });
    this.sectionHeaderActions = signal([this.editFilesAction]);
    this.loadDeviceFilesEffect();
  }

  onDispatchSectionHeaderEvent(event: SectionHeaderEvent): void {
    if (event.type === this.editFilesAction.action) {
      const device = this.device();
      this.deviceFilesService.onEditFiles(device);
    }
  }

  private loadDeviceFilesEffect(): void {
    effect(
      () => {
        const device = this.device();
        if (device?.id) {
          this.deviceFilesFacade.loadDeviceFiles(device);
        }
      },
      { allowSignalWrites: true }
    );
  }
}
