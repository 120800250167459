import { TrackingEventIds as BaseTrackingEventIds } from '../../common/tracking/tracking-event-ids.model';
import { NavigationRoutes } from '../navigation-routes.enum';

export const TrackingEventIds = {
  ...BaseTrackingEventIds,
  NAVIGATE_TO_DEVICES_MASTER_VIEW: NavigationRoutes.DEVICES,
  OPEN_DEVICES_FILTER_ENGINE: 'open_devices_filter_engine',
  SEND_DEVICE_SHELL_COMMAND: 'send_device_shell_command',
  SEND_DEVICE_OVERVIEW_COMMAND: 'send_device_overview_command',
  OPEN_DEVICE_AUDIT_TRAILS: 'open_device_audit_trails',
  OPEN_DEVICE_NAME_EDIT_FORM: 'open_device_name_edit_form',
  OPEN_DEVICE_AFFILIATE_EDIT_FORM: 'open_device_affiliate_edit_form',
  OPEN_DEVICE_GENERAL_INFO_EDIT_FORM: 'open_device_general-info_edit_form'
};
