import { DeviceDetails } from '@iot-platform/models/xmqtt';
import { get } from 'lodash';

export class DeviceHelpers {
  public static readonly REFRESH_COMMAND_ELIGIBLE_OPERATORS = ['Orange France', 'Vodafone WW'];

  public static readonly canSendRefreshCommand = (device: DeviceDetails): boolean => {
    const operator = get(device, ['communication', 'operator']);
    return DeviceHelpers.REFRESH_COMMAND_ELIGIBLE_OPERATORS.includes(operator);
  };
}
