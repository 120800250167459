import { inject, Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { AbstractMasterViewService } from '@iot-platform/feature/master-view';
import { IotToolbarDefaultButton } from '@iot-platform/iot-platform-ui';
import { FavoriteView, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { combineLatest, map, noop, Observable, of } from 'rxjs';
import { AbstractDeviceFilesService } from './abstract-device-files.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceFilesMasterViewService extends AbstractMasterViewService {
  protected readonly deviceFilesService: AbstractDeviceFilesService<any> = inject(AbstractDeviceFilesService);

  canCreateDevice$: Observable<boolean> = toObservable(this.deviceFilesService.canCreateDevice);
  canUpdateDevice$: Observable<boolean> = toObservable(this.deviceFilesService.canUpdateDevice);

  getPermissions(): Observable<
    {
      key: string;
      value: boolean;
    }[]
  > {
    return combineLatest([this.canCreateDevice$, this.canUpdateDevice$]).pipe(
      map(([canCreateDevice, canUpdateDevice]) => [
        {
          key: 'canUpdateDevice',
          value: canUpdateDevice
        },
        {
          key: 'canCreateDevice',
          value: canCreateDevice
        }
      ])
    );
  }

  getInitialFilters(): Observable<Filter[]> {
    return of([]);
  }

  getStaticGridDefinition(): Observable<I4BGrid<I4BGridOptions, I4BGridData> | null> {
    return this.deviceFilesService.getStaticGridDefinition();
  }

  onMasterViewEngineEvent(): void {
    noop();
  }

  onToolbarEvent() {
    noop();
  }

  getGridConfiguration(): Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> {
    return of(null);
  }

  getGrids(): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    return of([]);
  }

  getToolbarButtons(): Observable<IotToolbarDefaultButton[]> {
    return of([]);
  }

  getFavoriteViewsConfiguration(): Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> {
    return of(null);
  }

  canUpdateBusinessProfile(): Observable<boolean> {
    return of(false);
  }

  onBusinessProfileChange() {
    noop();
  }
}
