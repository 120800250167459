import { signal } from '@angular/core';
import { FeatureMasterViewSettings } from '@iot-platform/feature/master-view';
import { FilterEngineMode } from '@iot-platform/iot-platform-ui';
import { FeatureDeviceFilesSettings } from '../models/device-files-settings.model';

export const getDeviceFilesMasterViewSettings = (settings: FeatureDeviceFilesSettings): Partial<FeatureMasterViewSettings> => ({
  masterViewName: signal(settings.masterViewName),
  grid: {
    staticDefinitionType: signal(true),
    hidePaginator: signal(true)
  },
  toolbar: {
    enabled: signal(false),
    withFavoriteViews: signal(false),
    withGrids: signal(false)
  },
  sectionHeader: {
    enabled: signal(false)
  },
  filterEngine: {
    enabled: signal(false),
    mode: signal(FilterEngineMode.FAVORITE)
  }
});
