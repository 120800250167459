import { Component, effect, inject, Signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { UserPreferences } from '@iot-platform/models/common';
import { NotificationModule } from '@iot-platform/notification';
import { fromUserPreferences, PreferencesActions, UserProfileService } from '@iot-platform/users';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  imports: [RouterModule, NotificationModule],
  selector: 'xmqtt-root',
  template: `
    <div class="default-theme">
      <router-outlet></router-outlet>
      <iot-platform-notification></iot-platform-notification>
    </div>
  `
})
export class AppComponent {
  protected readonly store: Store = inject(Store);
  protected readonly userProfileService: UserProfileService = inject(UserProfileService);
  protected readonly iconRegistry: MatIconRegistry = inject(MatIconRegistry);
  protected readonly sanitizer: DomSanitizer = inject(DomSanitizer);
  private preferences: Signal<UserPreferences> = this.store.selectSignal(fromUserPreferences.getPreferences);

  constructor() {
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iot4bos-icon-set.svg'));
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/xmqtt-icon-set.svg'));

    effect(
      () => {
        const preferences = this.preferences();
        if (preferences) {
          this.userProfileService.setThemeOverlay(preferences.appTheme);
          this.store.dispatch(PreferencesActions.changeLanguage({ lang: preferences.appLanguage }));
        }
      },
      { allowSignalWrites: true }
    );
  }
}
