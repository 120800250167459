import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
  standalone: true,
  imports: [NgClass],
  selector: 'grid-engine-basic-cell',
  templateUrl: './basic-cell.component.html',
  styleUrls: ['./basic-cell.component.scss']
})
export class BasicCellComponent implements ICellRendererAngularComp {
  sanitizer: DomSanitizer = inject(DomSanitizer);
  params: WritableSignal<CustomCellParams> = signal(null);
  isEmpty: Signal<boolean> = computed(() => {
    const params = this.params();
    return !params?.value && params?.value !== 0 && params?.value !== false;
  });

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
