import { DeviceFile, DeviceFileType } from '@iot-platform/models/common';
import { get } from 'lodash';

export class DeviceFileHelpers {
  public static excludeFilesByTypes = (files: DeviceFile[], types: DeviceFileType[]): DeviceFile[] =>
    files.filter((f: DeviceFile) => !types.map((type: DeviceFileType) => type.toString().toLowerCase()).includes(f.type.toLowerCase()));

  public static getFiles = (data: any, path: string[]): DeviceFile[] => {
    const files: DeviceFile[] = get(data, path, []) || [];
    return files.map((file: DeviceFile, index: number) => ({
      ...file,
      id: `${file.name}_${index}${file.deviceId}`
    }));
  };
  public static getAvailableFiles = (files: DeviceFile[]): DeviceFile[] => DeviceFileHelpers.getFilesByDownload(files, false);
  public static getSelectedFiles = (files: DeviceFile[]): DeviceFile[] => DeviceFileHelpers.getFilesByDownload(files, true);
  private static getFilesByDownload = (files: DeviceFile[], download: boolean) => (files ? files.filter((f: DeviceFile) => f.download === download) : []);
}
