import { inject } from '@angular/core';
import { CommonApiResponse, DeviceLastCommandErrorStatus, Pagination } from '@iot-platform/models/common';
import { I4BBulkOperationApiResponseStatuses } from '@iot-platform/models/i4b';
import { BulkOperationApiResponse, DeviceDetails } from '@iot-platform/models/xmqtt';
import { NotificationService } from '@iot-platform/notification';
import { DevicesFacade } from '@iot-platform/xmqtt/data-access/devices';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { DevicesService } from '../../services/devices.service';
import { DevicesActions } from '../actions';

const loadDevices$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDevices),
      switchMap(({ request }) =>
        devicesService.getAll(request).pipe(
          map((response: CommonApiResponse<DeviceDetails, Pagination>) => DevicesActions.loadDevicesSuccess({ response })),
          catchError((error) => of(DevicesActions.loadDevicesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadDeviceById$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceById),
      switchMap((action) =>
        devicesService.getDeviceDetails(action.id).pipe(
          map((response: DeviceDetails) => DevicesActions.loadDeviceByIdSuccess({ response })),
          catchError((error) => of(DevicesActions.loadDeviceByIdFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateDevice$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.updateDevice),
      concatMap((action) =>
        devicesService.updateDevice(action.device).pipe(
          map((response: DeviceDetails) => DevicesActions.updateDeviceSuccess({ response })),
          catchError((error) => of(DevicesActions.updateDeviceFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateDeviceSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesFacade = inject(DevicesFacade)) =>
    actions$.pipe(
      ofType(DevicesActions.updateDeviceSuccess),
      tap(({ response }) => {
        devicesFacade.updateDeviceInCurrentGrid(response);
      })
    ),
  { functional: true, dispatch: false }
);
/* eslint-disable @typescript-eslint/no-explicit-any */
const bulkEditDevicesSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesFacade = inject(DevicesFacade), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DevicesActions.bulkEditDevicesSuccess),
      tap((action: any) => {
        (Object.entries(action.response.devices) as any[]).forEach(([identifier, { operations }]) => {
          const values = (Object.entries(operations) as any[]).map(([key, { success }]) => ({ key, success }));
          const successOperations = values.filter(({ success }) => success);
          if (successOperations.length) {
            const updatedFields = successOperations.reduce((acc, { key }) => {
              const obj: any = {}
              if (key === 'affiliateId') {
                obj.affiliate = action.properties.affiliateId;
                obj.affiliateName = action.properties.affiliateName;
              } else if (key !== 'timezone') {
                obj[key] = action.properties[key];
              }
              return { ...acc, ...obj};
            }, {});
            const device = action.devices.find((d) => d.identifier === identifier);
            devicesFacade.updateDeviceInCurrentGrid({
              ...device,
              ...updatedFields
            });
          }
          notificationService.displaySuccess(`${action.type} [${I4BBulkOperationApiResponseStatuses[action.response.status]}]`);
        });
      })
    ),
  { functional: true, dispatch: false }
);

const loadDeviceByIdSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceByIdSuccess),
      map(({ response }) => DevicesActions.setCurrentDevice({ device: response }))
    ),
  { functional: true }
);

const sendCommand$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.sendCommand),
      switchMap(({ device, command }) =>
        devicesService.sendCommand(device, command).pipe(
          map(() => DevicesActions.sendCommandSuccess({ response: device })),
          catchError((error) => of(DevicesActions.sendCommandFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const bulkEditDevices$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.bulkEditDevices),
      switchMap(({ devices, properties }) =>
        devicesService.bulkEditDevices(devices, properties).pipe(
          map((response: BulkOperationApiResponse) =>
            DevicesActions.bulkEditDevicesSuccess({
              response,
              devices,
              properties
            })
          ),
          catchError((error) => of(DevicesActions.bulkEditDevicesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesActions.loadDevices,
        DevicesActions.loadDeviceById,
        DevicesActions.sendCommand,
        DevicesActions.updateDevice,
        DevicesActions.bulkEditDevices
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesActions.loadDevicesSuccess,
        DevicesActions.loadDevicesFailure,
        DevicesActions.loadDeviceByIdSuccess,
        DevicesActions.loadDeviceByIdFailure,
        DevicesActions.sendCommandSuccess,
        DevicesActions.sendCommandFailure,
        DevicesActions.updateDeviceSuccess,
        DevicesActions.updateDeviceFailure,
        DevicesActions.bulkEditDevicesSuccess,
        DevicesActions.bulkEditDevicesFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesActions.loadDevicesFailure,
        DevicesActions.loadDeviceByIdFailure,
        DevicesActions.updateDeviceFailure,
        DevicesActions.bulkEditDevicesFailure
      ),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

const sendCommandFailure$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService), translateService = inject(TranslateService)) =>
    actions$.pipe(
      ofType(DevicesActions.sendCommandFailure),
      tap((action: Action) => {
        const error = get(action, ['error', 'error', 'error']);
        const errors = [
          DeviceLastCommandErrorStatus.ABORTED_NOT_LISTENING_MODE.toString(),
          DeviceLastCommandErrorStatus.ABORTED_FUNCTION_NOT_AVAILABLE.toString()
        ];
        let errorMsg = action.type;
        if (errors.includes(error)) {
          errorMsg = `${errorMsg} - ${translateService.instant(`DEVICES.COMMANDS_STATUSES.${error}`)}`;
        }
        notificationService.displayError(errorMsg);
      })
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DevicesActions.sendCommandSuccess, DevicesActions.updateDeviceSuccess, DevicesActions.bulkEditDevicesSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const sendCommandSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(DevicesActions.sendCommandSuccess),
      map(({ response }) => DevicesActions.loadDeviceById({ id: response.id }))
    ),
  { functional: true }
);

export const DevicesEffects = {
  loadDevices$,
  loadDeviceById$,
  loadDeviceByIdSuccess$,
  showLoader$,
  hideLoader$,
  displayError$,
  updateDevice$,
  sendCommand$,
  displaySuccess$,
  sendCommandFailure$,
  sendCommandSuccess$,
  updateDeviceSuccess$,
  bulkEditDevices$,
  bulkEditDevicesSuccess$
};
