import { AgGridAngular } from '@ag-grid-community/angular';
import { ColDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent, IRowNode, RowDoubleClickedEvent, RowNode } from '@ag-grid-community/core';
import { FilterChangedEvent } from '@ag-grid-community/core/dist/types/src/events';
import { UpperCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DateCellComponent } from '@iot-platform/grid-engine';
import { DeviceFile } from '@iot-platform/models/common';
import { I4BCellType } from '@iot-platform/models/grid-engine';
import { DeviceFileHelpers } from '@iot-platform/util/devices';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, MatCardModule, MatToolbarModule, MatIconModule, UpperCasePipe, MatButtonModule, AgGridAngular],
  selector: 'iot-platform-feature-device-files-files-form-dialog',
  templateUrl: './device-files-form-dialog.component.html',
  styleUrls: ['./device-files-form-dialog.component.scss']
})
export class DeviceFilesFormDialogComponent {
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly dialogRef: MatDialogRef<DeviceFilesFormDialogComponent> = inject(MatDialogRef<DeviceFilesFormDialogComponent>);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly textColumnFilterDef: Partial<ColDef> = {
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith']
    }
  };
  private readonly dateColumnFilterDef: Partial<ColDef> = {
    filter: 'agDateColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      browserDatePicker: true,
      maxValidYear: new Date().getFullYear(),
      inRangeFloatingFilterDateFormat: 'YYYY-MM-DD',
      comparator: this.dateComparator.bind(this)
    }
  };
  private readonly filterOptions: string[] = [
    // Set Filter
    'selectAll',
    'selectAllSearchResults',
    'searchOoo',
    'blanks',
    'noMatches',

    // Number Filter & Text Filter
    'filterOoo',
    'equals',
    'notEqual',
    'blank',
    'notBlank',
    'empty',

    // Number Filter
    'lessThan',
    'greaterThan',
    'lessThanOrEqual',
    'greaterThanOrEqual',
    'inRange',
    'inRangeStart',
    'inRangeEnd',

    // Text Filter
    'contains',
    'notContains',
    'startsWith',
    'endsWith',

    // Date Filter
    'dateFormatOoo',

    // Filter Conditions
    'andCondition',
    'orCondition',

    // Filter Buttons
    'applyFilter',
    'resetFilter',
    'clearFilter',
    'cancelFilter',

    // Filter Titles
    'textFilter',
    'numberFilter',
    'dateFilter',
    'setFilter'
  ];
  private readonly commonGridOptions: GridOptions = {
    rowDragManaged: true,
    animateRows: true,
    rowDragMultiRow: true,
    rowSelection: 'multiple',
    enableCellTextSelection: true,
    suppressRowDeselection: true,
    suppressRowClickSelection: true,
    suppressDragLeaveHidesColumns: true,
    defaultColDef: {
      minWidth: 80,
      sortable: true,
      filter: true,
      resizable: true,
      lockPinned: true,
      suppressFiltersToolPanel: true,
      menuTabs: ['filterMenuTab']
    },
    getRowId: (params) => params.data.id,
    rowData: [],
    components: {
      [I4BCellType.DATE]: DateCellComponent
    }
  };
  public columnDefs: ColDef[] = [];
  public enableLeftClearAppliedFilter = false;
  public enableRightClearAppliedFilter = false;
  public loading: WritableSignal<boolean> = signal(true);
  public leftGridApi: GridApi;
  public rightGridApi: GridApi;
  // We can pass an http call to retrieve the files
  public data: { files$: Observable<DeviceFile[]> } = inject(MAT_DIALOG_DATA);
  public leftRowNodeCount: WritableSignal<any> = signal(0);
  public rightRowNodeCount: WritableSignal<number> = signal(0);
  public allFiles: Signal<DeviceFile[]> = toSignal(
    this.data.files$.pipe(
      finalize(() => this.loading.set(false)),
      takeUntilDestroyed(this.destroyRef)
    )
  );
  leftGridOptions: GridOptions = {
    ...this.commonGridOptions,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.moveElements(event.data, 'right', false);
    },
    onFilterChanged: (params: FilterChangedEvent) => this.onFilterChanged(params, 'enableLeftClearAppliedFilter')
  };
  rightGridOptions: GridOptions = {
    ...this.commonGridOptions,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      this.moveElements(event.data, 'left', false);
    },
    onFilterChanged: (params: FilterChangedEvent) => this.onFilterChanged(params, 'enableRightClearAppliedFilter')
  };
  availableFiles: Signal<DeviceFile[]> = computed(() => {
    const allFiles = this.allFiles();
    return DeviceFileHelpers.getAvailableFiles(allFiles);
  });
  selectedFiles: Signal<DeviceFile[]> = computed(() => {
    const allFiles = this.allFiles();
    return DeviceFileHelpers.getSelectedFiles(allFiles);
  });

  constructor() {
    this.columnDefs = this.getColumnDef();

    effect(
      () => {
        const availableFiles = this.availableFiles();
        this.leftRowNodeCount.set(availableFiles?.length);
      },
      { allowSignalWrites: true }
    );
    effect(
      () => {
        const selectedFiles = this.selectedFiles();
        this.rightRowNodeCount.set(selectedFiles?.length);
      },
      { allowSignalWrites: true }
    );
  }

  onFilterChanged(params: FilterChangedEvent, attributeName: string): void {
    this[attributeName] = !!Object.keys(params.api.getFilterModel()).length;
  }

  onClearFilters(gridApi: GridApi): void {
    gridApi.deselectAll();
    gridApi.setFilterModel(null);
  }

  onLeftGridReady(params: GridReadyEvent): void {
    this.leftGridApi = params.api;
  }

  onRightGridReady(params: GridReadyEvent): void {
    this.rightGridApi = params.api;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    const rightRowData: DeviceFile[] = this.getRowData(this.rightGridApi);
    const availableFiles: DeviceFile[] = this.availableFiles();
    const selectedFiles: DeviceFile[] = this.selectedFiles();
    const allFiles: DeviceFile[] = [...availableFiles, ...selectedFiles];
    const filesToUpdate: DeviceFile[] = allFiles.map((f: DeviceFile) => ({
      ...f,
      download: !!rightRowData.find((node: DeviceFile) => node.id === f.id)
    }));
    this.dialogRef.close(filesToUpdate);
  }

  onDragStart(event: DragEvent, gridApi: GridApi): void {
    const filteredSelectedRows: DeviceFile[] = [];
    gridApi.forEachNodeAfterFilter((node: RowNode) => {
      filteredSelectedRows.push(node.data);
    });
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows && !!selectedRows.length && !!filteredSelectedRows.length) {
      const files: DeviceFile[] = selectedRows.reduce((acc: DeviceFile[], file: DeviceFile) => {
        const found: DeviceFile = filteredSelectedRows.find((f: DeviceFile) => f.id === file.id);
        return found ? [...acc, file] : [...acc];
      }, []);
      event.dataTransfer.setData('application/json', JSON.stringify(files));
    }
  }

  gridDragOver(event: DragEvent): void {
    const dragSupported = event.dataTransfer.types.length;
    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy';
      event.preventDefault();
    }
  }

  gridDrop(event: DragEvent, target: string): void {
    event.preventDefault();
    const data = JSON.parse(event.dataTransfer.getData('application/json'));
    this.moveElements(data, target);
  }

  onFirstDataRendered(params: FirstDataRenderedEvent): void {
    params.api.sizeColumnsToFit();
  }

  getLocaleTexts(): { [key: string]: string } {
    const locals: { [key: string]: string } = {};
    this.filterOptions.forEach((optionKey: string) => {
      locals[optionKey] = this.translateKey(`AG_GRID.${optionKey}`);
    });
    return locals;
  }

  moveElements(data: DeviceFile | DeviceFile[], target: string, isDraggableItem = true): void {
    const isRowInGrid = (_gridApi: GridApi, _data: DeviceFile): boolean => {
      const rowNode: IRowNode = _gridApi && _gridApi.getRowNode(_data.id);
      return rowNode !== null && rowNode !== undefined;
    };
    const applyTransaction = (_gridApi: GridApi, _data: DeviceFile, action: 'add' | 'remove') => {
      _gridApi.applyTransaction({
        [action]: [_data]
      });
    };

    const gridApiToAdd: GridApi = target === 'left' ? this.leftGridApi : this.rightGridApi;
    const gridApiToRemove: GridApi = target === 'left' ? this.rightGridApi : this.leftGridApi;

    // if data missing or data has no id, do nothing
    const isArray = data instanceof Array;
    if (!data || (isArray && !data.length) || (!isArray && data.id == null)) {
      return;
    }

    // do nothing if row is already in the grid, otherwise we would have duplicates
    if (isDraggableItem && isRowInGrid(gridApiToAdd, isArray ? data[0] : data)) {
      return;
    }

    if (isArray) {
      data.forEach((element: DeviceFile) => {
        applyTransaction(gridApiToAdd, element, 'add');
        applyTransaction(gridApiToRemove, element, 'remove');
      });
    } else {
      applyTransaction(gridApiToAdd, data, 'add');
      applyTransaction(gridApiToRemove, data, 'remove');
    }

    // Reset target grid selection and filters
    if (gridApiToAdd) {
      this.onClearFilters(gridApiToAdd);
    }

    this.leftRowNodeCount.set(this.getRowData(this.leftGridApi).length);
    this.rightRowNodeCount.set(this.getRowData(this.rightGridApi).length);
  }

  dateComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
    if (cellValue === null) {
      return -1;
    }
    const currentCellDate: Date = new Date(cellValue);
    const cellDate: Date = new Date(currentCellDate.getFullYear(), currentCellDate.getMonth(), currentCellDate.getDate());
    const filterDate: Date = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), filterLocalDateAtMidnight.getDate());
    if (filterDate.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterDate) {
      return -1;
    }
    if (cellDate > filterDate) {
      return 1;
    }
  }

  private getRowData(gridApi: GridApi): DeviceFile[] {
    const rowData: DeviceFile[] = [];
    gridApi.forEachNode(({ data }) => {
      rowData.push(data);
    });
    return rowData;
  }

  private getColumnDef(): ColDef[] {
    return [
      {
        field: 'id',
        hide: true
      },
      {
        headerName: this.translateKey('DEVICES.FILES_POPUP.DEVICE_NAME'),
        field: 'deviceName',
        ...this.textColumnFilterDef,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        lockPosition: true,
        rowDrag: false,
        dndSource: true,
        cellClass: 'ag-custom-selection-cell',
        minWidth: 150
      },
      {
        headerName: this.translateKey('DEVICES.FILES_POPUP.FILE_NAME'),
        field: 'name',
        ...this.textColumnFilterDef,
        minWidth: 100
      },
      {
        headerName: this.translateKey('DEVICES.FILES_POPUP.FILE_TYPE'),
        field: 'type',
        filter: 'agSetColumnFilter',
        minWidth: 100
      },
      {
        headerName: this.translateKey('DEVICES.FILES_POPUP.FILE_LAST_SUCCEEDED'),
        field: 'lastReadSucceeded',
        cellRenderer: I4BCellType.DATE,
        ...this.dateColumnFilterDef,
        minWidth: 150
      },
      {
        headerName: this.translateKey('DEVICES.FILES_POPUP.IDENTIFIER'),
        field: 'deviceId',
        ...this.textColumnFilterDef,
        minWidth: 200
      }
    ].map((col: ColDef) => ({
      ...col,
      filterParams: {
        ...col.filterParams,
        buttons: ['clear'],
        closeOnApply: true
      }
    }));
  }

  private translateKey(key: string): string {
    return this.translateService.instant(key);
  }
}
