import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { DeviceDetails } from '@iot-platform/models/xmqtt';

@Injectable({
  providedIn: 'root'
})
export class NavigationsService {
  protected readonly router: Router = inject(Router);
  protected readonly storage: LocalStorageService = inject(LocalStorageService);

  returnToOrigin(): void {
    const route = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
    this.router.navigate(route ? ['/', route] : ['/']);
  }

  gotToDeviceOverviewPage(device: DeviceDetails, origin: string): void {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
    this.router.navigateByUrl(`/${origin}/${device.id}`);
  }
}
