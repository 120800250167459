import { AfterViewInit, Component, effect, forwardRef, inject, Injector, input, OnDestroy, output, signal, Signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { DynamicDataResponse, Filter } from '@iot-platform/models/common';
import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import * as Leaflet from 'leaflet';
import { Layer, PopupOptions } from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet.markercluster';
import { cloneDeep, debounce, get } from 'lodash';
import { MapClustersHelper } from '../../helpers/map-clusters.helper';
import { MapLayersHelper } from '../../helpers/map-layers.helper';
import { MapMarkersHelper } from '../../helpers/map-markers.helper';
import {
  IotGeoJsonFeature,
  IotGeoJsonRouteFeature,
  IotMapActionType,
  IotMapDisplayMode,
  IotMapDisplayType,
  IotMapEvent,
  IotMapMarkerPopup
} from '../../models';
import { MapNavigationEvent } from '../../models/iot-map-navigation-event.model';
import { MapPopupService } from '../../services/map-popup.service';
import { MapFacade } from '../../state/facades/map.facade';
import { MapPanelInfoComponent } from '../map-panel-info/map-panel-info.component';
import { MapSpinnerComponent } from '../map-spinner/map-spinner.component';
import LayersOptions = Leaflet.Control.LayersOptions;

Leaflet.Icon.Default.imagePath = 'assets/map';

const MAX_ZOOM = 18;
const MIN_ZOOM = 2;

@Component({
  standalone: true,
  imports: [FlexLayoutModule, MapSpinnerComponent, MatSidenavModule, LeafletModule, LeafletMarkerClusterModule, forwardRef(() => MapPanelInfoComponent)],
  selector: 'iot-platform-maps-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnDestroy {
  private readonly mapFacade: MapFacade = inject(MapFacade);
  private readonly popupService: MapPopupService = inject(MapPopupService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly injector: Injector = inject(Injector);

  analytic: AnalyticsService = new AnalyticsService('map');

  concept = input<string>('sites');
  filters = input<Filter[]>([]);
  displayMode = input<IotMapDisplayMode>('Basic');
  displayType = input<IotMapDisplayType>(IotMapDisplayType.CLUSTER);
  displayChunks = input<boolean>(false); //  Display chunks of dataset only in the displayed area of the map, This can resolve performance issue displayType = IotMapDisplayType.POINT
  zoom = input<number>(6);
  defaultCoordinates = input<number[]>([48, 2.5]);

  dispatchEvent = output<IotMapEvent>();
  dispatchMapNavigationEvent = output<MapNavigationEvent<Site | Asset | Device>>();

  currentDisplayMode: WritableSignal<IotMapDisplayMode> = signal('Basic');

  map!: Leaflet.Map;
  features: IotGeoJsonFeature[] = [];
  routes: IotGeoJsonRouteFeature[] = [];
  selectedMarker!: Leaflet.Marker | null;
  markers: WritableSignal<Leaflet.Marker[]> = signal([]);
  assetVariable: any;

  clusterOptions: Leaflet.MarkerClusterGroupOptions = {
    iconCreateFunction: (cluster: Leaflet.MarkerCluster) => MapClustersHelper.createClusterIcon(cluster, this.concept(), this.currentDisplayMode()),
    spiderfyDistanceMultiplier: 1.1,
    maxClusterRadius: 70
  };

  layersControlOptions: LayersOptions = { position: 'topleft', collapsed: false };

  baseLayers: {
    [name: string]: Leaflet.Layer;
  } = {
    OpenStreetMap: Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: MAX_ZOOM,
      attribution: undefined,
      minZoom: MIN_ZOOM
    }) as Leaflet.Layer
  };

  routeLayers = {};

  options: Leaflet.MapOptions = {
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
    center: this.defaultPosition,
    zoomControl: true,
    attributionControl: false
  };

  displayPanelInfo: WritableSignal<boolean> = signal(false);
  selectedFeature: WritableSignal<IotGeoJsonFeature | undefined> = signal(undefined);

  loading: Signal<boolean> = this.mapFacade.maploading;
  refresh: Signal<boolean> = this.mapFacade.refresh;

  applicablePopup: WritableSignal<IotMapMarkerPopup> = signal(this.popupService.getPopup(this.concept(), this.currentDisplayMode()));
  selectedLayers: WritableSignal<{ [concept: string]: string }> = signal(
    this.storage.get(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY)
      ? JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY))
      : { sites: 'Basic', assets: 'Basic', devices: 'CCF' }
  );

  popupLoadingContent = `<b class='leaflet-popup-content__section'>${this.translateService.instant('CARD_LOADER.LOADING')}</b>`;

  displayModeEffect = effect(
    () => {
      const displayMode = this.displayMode();
      if (displayMode) {
        this.currentDisplayMode.set(displayMode);
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  conceptEffect = effect(
    () => {
      const concept = this.concept();
      if (concept) {
        this.mapFacade.setConcept(concept);
        this.applicablePopup.set(this.popupService.getPopup(this.concept(), this.currentDisplayMode()));
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  filtersEffect = effect(
    () => {
      const filters = this.filters();
      if (filters) {
        this.cleanRoutes();
        this.mapFacade.clearRoutes();
        this.displayPanelInfo.set(false);
        this.loadData();
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  defaultCoordinatesEffect = effect(
    () => {
      const defaultCoordinates = this.defaultCoordinates();
      if (defaultCoordinates) {
        this.options.center = this.defaultPosition;
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  refreshEffect = effect(
    () => {
      const refresh = this.refresh();
      if (refresh) {
        this.loadData();
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  markersEffect = effect(
    () => {
      const markers = this.markers();
      this.removeMarkers();
      const feature = new Leaflet.MarkerClusterGroup(this.clusterOptions);
      feature.addLayers(markers);

      if (this.map && feature.getBounds().isValid()) {
        this.map.addLayer(feature);
        this.map.fitBounds(feature.getBounds());
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  selectedLayersEffect = effect(
    () => {
      const selectedLayers = this.selectedLayers();

      this.storage.set(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY, JSON.stringify(selectedLayers));
      const currentDisplayMode: IotMapDisplayMode = MapLayersHelper.getDisplayModeByLayer(this.concept(), selectedLayers[this.concept()]);
      this.applicablePopup.set(this.popupService.getPopup(this.concept(), currentDisplayMode));
      if (this.selectedMarker) {
        this.selectedMarker.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.currentDisplayMode()));
        this.selectedMarker = null;
      }
      this.displayPanelInfo.set(false);
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  get defaultPosition(): Leaflet.LatLng {
    return Leaflet.latLng(this.defaultCoordinates() as Leaflet.LatLngTuple);
  }

  ngAfterViewInit(): void {
    const timeout = setTimeout(() => {
      this.map.invalidateSize();
      if (this.markers().length > 0) {
        this.markers.set(this.markers()); // Forced to redraw map after switching to grid
      }
      clearTimeout(timeout);
    }, 100);
  }

  onMapReady(map: Leaflet.Map): void {
    this.map = map;
    this.dispatchEvent.emit({
      type: IotMapActionType.MAP_READY,
      map,
      popup: this.applicablePopup() ? this.applicablePopup() : this.popupService.getPopup(this.concept(), this.currentDisplayMode())
    });

    this.initFeatures();
    this.initRoutes();
    this.onMapMoveEnd();
    this.manageAdditionalBaseLayers();
  }

  onMapMoveEnd(): void {
    this.map.on(
      'moveend',
      debounce(() => {
        this.dispatchEvent.emit({
          type: IotMapActionType.MAP_MOVE_END,
          map: this.map
        });
        if (this.displayChunks()) {
          this.removeMarkers();
          this.initMarkers();
        }
      }, 200)
    );
  }

  loadData(): void {
    const selectedLayerConfig = MapLayersHelper.getSelectedLayerConfig(this.concept(), this.selectedLayers()[this.concept()]);
    if (selectedLayerConfig) {
      const additionalFilter: Filter | undefined = selectedLayerConfig.additionalFilter;
      this.mapFacade.getAll({
        concept: this.concept(),
        displayMode: selectedLayerConfig.displayMode,
        filters: additionalFilter ? [...this.filters(), additionalFilter] : this.filters()
      });
    } else {
      this.mapFacade.getAll({ concept: this.concept(), displayMode: this.currentDisplayMode(), filters: this.filters() });
    }
  }

  cleanRoutes(): void {
    if (this.map) {
      this.map.eachLayer((layer: Leaflet.Layer) => {
        if (layer instanceof Leaflet.GeoJSON) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  initRoutes(): void {
    effect(
      () => {
        this.mapFacade.routesLoaded();
        this.mapFacade.routesLoading();
        this.mapFacade.hasRoutes();
        this.mapFacade.currentRoutes();

        this.cleanRoutes();
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  initFeatures(): void {
    effect(
      () => {
        const currentFeatures = this.mapFacade.currentFeatures();
        this.features = [...currentFeatures];
        if (currentFeatures.length === 0) {
          this.map.panTo(this.defaultPosition);
          this.map.setZoom(3);
        }
        this.initMarkers();
      },
      { injector: this.injector, allowSignalWrites: true }
    );

    if (!this.features.length) {
      this.loadData();
    }
  }

  initMarkers(): void {
    const markers: Leaflet.Marker[] = [];
    this.features.forEach((feature: IotGeoJsonFeature | IotGeoJsonRouteFeature) => {
      if (this.displayType() === IotMapDisplayType.CLUSTER || this.displayType() === IotMapDisplayType.POINT) {
        const marker: Leaflet.Marker = this.generateMarker(feature as IotGeoJsonFeature);
        if (this.hasValidCoordinates(marker) && this.isMarkerInMapBound(marker)) {
          markers.push(marker);
        }
        if (this.hasValidCoordinates(marker) && this.displayType() === IotMapDisplayType.POINT) {
          this.map.addLayer(marker);
        }
      }
    });
    if (this.displayType() === IotMapDisplayType.CLUSTER) {
      this.markers.set([...markers]);
    }
  }

  getPopup(data: DynamicDataResponse, feature: IotGeoJsonFeature): IotMapMarkerPopup {
    const popup: IotMapMarkerPopup = new IotMapMarkerPopup({ ...this.applicablePopup().options, data, feature });
    popup.templateRows = this.applicablePopup().templateRows;
    return popup.build();
  }

  generateRoutePoint(feature: IotGeoJsonFeature): Leaflet.Marker {
    const marker: Leaflet.Marker = Leaflet.marker(Leaflet.latLng([feature.geometry.coordinates[1], feature.geometry.coordinates[0]] as Leaflet.LatLngTuple), {
      draggable: false,
      icon: Leaflet.divIcon({
        html: '<svg style="width: 3px; height: 3px;"><circle r="3" cx="3" cy="3" stroke-width="1" fill="#bf6660"></circle><svg>',
        iconSize: [0, 0],
        iconAnchor: [3, 3]
      })
    });
    marker.feature = feature;

    return marker;
  }

  generateMarker(feature: IotGeoJsonFeature): Leaflet.Marker {
    const marker: Leaflet.Marker = Leaflet.marker(Leaflet.latLng([feature.geometry.coordinates[1], feature.geometry.coordinates[0]] as Leaflet.LatLngTuple), {
      draggable: false,
      icon: MapMarkersHelper.getMarkerIcon(feature, this.currentDisplayMode())
    });
    marker.feature = feature;
    if (get(this.applicablePopup(), 'displayPopup')) {
      const popupOptions: PopupOptions = {
        autoClose: true,
        closeButton: false,
        offset: Leaflet.point(-160, -25)
      };
      marker.bindPopup(get(this.applicablePopup(), 'loadData') ? this.popupLoadingContent : this.getPopup(null, feature), popupOptions);
    }
    marker.on('click', (event: Leaflet.LeafletMouseEvent) => this.markerClicked(event, feature));
    marker.on('mouseover', (event: Leaflet.LeafletMouseEvent) => this.markerHovered(event, feature));
    marker.on('mouseout', (event: Leaflet.LeafletMouseEvent) => this.markerLeaved(event, feature));
    return marker;
  }

  markerHovered(event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    this.analytic.log('map-actions', 'marker-hovered');
    event.target.openPopup();
    if (this.selectedMarker?.feature?.properties.id !== event.target.feature.properties.id) {
      event.target.setIcon(MapMarkersHelper.getMarkerIconHover(feature, this.currentDisplayMode()));
    }
  }

  markerLeaved(event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    event.target.closePopup();
    if (this.selectedMarker?.feature?.properties.id !== event.target.feature.properties.id) {
      event.target.setIcon(MapMarkersHelper.getMarkerIcon(feature, this.currentDisplayMode()));
    }
  }

  markerClicked($event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    this.analytic.log('map-actions', 'marker-clicked');
    this.cleanRoutes();
    this.mapFacade.clearRoutes();
    this.dispatchEvent.emit({
      type: IotMapActionType.MARKER_CLICK,
      marker: $event.target,
      feature
    });

    this.mapFacade.saveMapUiState($event.target);

    if (!this.displayPanelInfo()) {
      if (this.selectedMarker?.feature?.properties.id !== $event.target.feature.properties.id) {
        this.selectedMarker = $event.target;
        this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.currentDisplayMode()));
      } else {
        $event.target.setIcon(MapMarkersHelper.getMarkerIcon(feature, this.currentDisplayMode()));
        this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.currentDisplayMode()));
      }
      this.displayPanelInfo.set(true);
    } else {
      this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.currentDisplayMode()));
      this.selectedMarker = cloneDeep($event.target);
      this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.currentDisplayMode()));
    }

    this.selectedFeature.set(feature);
  }

  hasValidCoordinates(marker: Leaflet.Marker): boolean {
    const latLong: Leaflet.LatLng = marker.getLatLng();
    return Math.abs(latLong.lat) <= 90 && Math.abs(latLong.lng) <= 180;
  }

  isMarkerInMapBound(marker: Leaflet.Marker): boolean {
    return this.displayChunks() ? this.map.getBounds().contains(marker.getLatLng()) : true;
  }

  removeMarkers(): void {
    if (this.map) {
      this.map.eachLayer((layer: Leaflet.Layer) => {
        if (layer instanceof Leaflet.Marker || layer instanceof Leaflet.MarkerCluster || layer instanceof Leaflet.MarkerClusterGroup) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  initCurrentPosition(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.map.panTo(Leaflet.latLng([position.coords.latitude, position.coords.longitude]));
        },
        () => {
          this.map.panTo(this.defaultPosition);
        }
      );
    }
  }

  onElementSelection(event: MapNavigationEvent<Site | Asset | Device>) {
    this.dispatchMapNavigationEvent.emit(event);
  }

  onDisplaySegments(event: { layers: Layer[]; action: 'add' | 'remove' }) {
    if (event.action === 'add') {
      event.layers
        .sort((a: Leaflet.Layer, b: Leaflet.Layer) => (a > b ? 1 : -1))
        .forEach((layer) => {
          this.map.addLayer(layer);
        });
    } else {
      event.layers.forEach((layer) => {
        if (this.map.hasLayer(layer)) {
          this.map.removeLayer(layer);
        }
      });
    }

    const bounds: any[] = [];
    this.map.eachLayer((layer: Leaflet.Layer) => {
      if (layer instanceof Leaflet.GeoJSON) {
        bounds.push(layer.getBounds());
      }
    });

    if (bounds.length > 0) {
      this.map.fitBounds(bounds);
    }
  }

  onClosePanelInfo() {
    this.analytic.log('map-actions', 'close-panel-info');
    this.displayPanelInfo.set(false);
    this.cleanRoutes();
    if (this.selectedMarker) {
      this.selectedMarker.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.currentDisplayMode()));
      this.selectedMarker = null;
    }
  }

  onBaseLayerChange = (e: Leaflet.LayersControlEvent) => {
    const selectedLayerConfig = MapLayersHelper.getSelectedLayerConfig(this.concept(), e.name);

    if (selectedLayerConfig) {
      this.currentDisplayMode.set(selectedLayerConfig.displayMode);
      const additionalFilter: Filter | undefined = selectedLayerConfig.additionalFilter;
      this.mapFacade.getAll({
        concept: this.concept(),
        displayMode: selectedLayerConfig.displayMode,
        filters: additionalFilter ? [...this.filters(), additionalFilter] : this.filters()
      });
      this.cleanRoutes();
      this.mapFacade.clearRoutes();
      this.dispatchEvent.emit({
        type: IotMapActionType.CHANGE_DISPLAY_MODE,
        displayMode: this.currentDisplayMode()
      });

      this.selectedLayers.set({ ...this.selectedLayers(), [this.concept()]: e.name });
      this.analytic.log('map-actions', 'selected-layer-changed', `[${this.concept}] : ${e.name}`);
    }
  };

  ngOnDestroy(): void {
    this.cleanRoutes();
  }

  private manageAdditionalBaseLayers() {
    const layerControlForCurrentConcept: { [layerName: string]: Leaflet.LayerGroup } = MapLayersHelper.getLayersByConcept(this.concept());
    const layerControl = Leaflet.control.layers(layerControlForCurrentConcept, {}, this.layersControlOptions);
    layerControl.addTo(this.map);
    const activeLayer: string = this.selectedLayers()[this.concept()];

    if (activeLayer) {
      layerControlForCurrentConcept[activeLayer].addTo(this.map);
    }

    this.map.on('baselayerchange', this.onBaseLayerChange);
  }
}
